import React from 'react'

const TileSm = ({tile}) => {
    return (
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{tile.title}</h5>
                    <h1 class="card-text">{tile.number}</h1>
                </div>
            </div>
      </div>
       
    )
}

export default TileSm
