import React, { useState, useEffect } from 'react';
import { stringify } from 'query-string';
import { useParams } from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import PostSubEmail from './PostSubEmail/PostSubEmail';
import SubmissionCSV from './SubmissionCSV'
import Dashboard from './Dashboard/Dashboard';
import EmailGeneral from './EmailGeneral/EmailGeneral';
import Collectors from './Collectors/Collectors';
import UserSubmissions from './UserSubmissions/UserSubmissions'
const Submissions = (props) => {
    const [selectedPage, setSelectedPage] = useState('Dashboard')
    const allPages = [{name:'Dashboard', icon :"fas fa-home"},{name:'Submissions',icon:"fas fa-user"},{name: 'Collectors',icon:"fas fa-code-branch"},{name:'Email Conditions',icon:"fas fa-envelope"},{name:'General Email Settings',icon:"fas fa-toolbox"},{name:'Raw Data',icon:"fas fa-table"}]

  


    const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel, dist) => {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
     

        for(i=0;i<JSONData.length;i++){
            JSONData[i] = Object.assign(JSONData[i], JSONData[i].results)
            delete JSONData[i].results
        }
        if(dist){
          var newJson = []

          for(i=0;i<JSONData.length;i++){
            if(JSONData[i].collector === dist){
              newJson.push(JSONData[i])
            }
           
        }
        JSONData = newJson
        }
      

        console.log("THIS IS OUR OBJ", JSONData)
        delete JSONData.results
        var arrData =
          typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    
        var CSV = "";
    
        //This condition will generate the Label/Header
        if (ShowLabel) {
          var row = "";
    
          //This loop will extract the label from 1st index of on array
          for (var index in arrData[0]) {
            //Now convert each value to string and comma-seprated
            row += index + ",";
          }
    
          row = row.slice(0, -1);
    
          //append Label row with line break
          CSV += row + "\r\n";
        }
    
        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
          var row = "";
    
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }
    
          row.slice(0, row.length - 1);
    
          //add a line break after each row
          CSV += row + "\r\n";
        }
    
        if (CSV === "") {
          alert("Invalid data");
          return;
        }
    
        //Generate a file name
        var fileName = "argus_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");
    
        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    
        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension
    
        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;
    
        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
    
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        props.setUpdate(props.update + 1)
      };

    return (
   <div>

    <SideBar 
    allPages={allPages} 
    selectedPage={selectedPage} 
    setSelectedPage={setSelectedPage}/>

    <br />
    <div className="container">
      <MainPage 
              selectedPage={selectedPage}
              serverAddress={props.serverAddress}
              token={props.token}
              submissions = {props.submissions} 
              collectors={props.collectors}
              JSONToCSVConvertor={JSONToCSVConvertor}
              openSubmissionModal={props.openSubmissionModal}
              />
    </div>
           

 

   </div>          
  
    )
}


const MainPage = ({selectedPage, serverAddress, token, submissions , collectors,JSONToCSVConvertor, openSubmissionModal}) => {
  switch (selectedPage){
    case 'Dashboard': return <Dashboard submissions={submissions} collectors={collectors} openSubmissionModal={openSubmissionModal} serverAddress={serverAddress} token={token}/>
    case 'Collectors': return <Collectors token={token} serverAddress ={serverAddress}/>

    case 'Submissions': return   <UserSubmissions submissions={submissions} openSubmissionModal={openSubmissionModal} token={token} serverAddress={serverAddress}/>

    case 'Email Conditions': return <PostSubEmail token={token} serverAddress ={serverAddress}/>

    case 'General Email Settings': return  <EmailGeneral token={token} serverAddress ={serverAddress}/>

    case 'Raw Data': return <SubmissionCSV JSONToCSVConvertor={JSONToCSVConvertor} submissions={submissions} collectors={collectors}/>

    default:  return <Dashboard submissions={submissions} collectors={collectors}/>
}




}


export default Submissions

