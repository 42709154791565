import React, { useState, useEffect } from 'react';

const UserSubmissions = ({submissions, openSubmissionModal, token, serverAddress}) => {
    const [reversed, setReversed]  = useState([])
    function reverseArray (arr) {
        var newArr = [];
        for (var i = 0, j = arr.length - 1; i < arr.length; i++, j--) {      
            newArr[i] = arr[j];
        }   
        return newArr;
    }
    useEffect(() => {  
        var newArr = reverseArray(submissions)
    setReversed(newArr)
   

    },[ submissions ]) 

    
    return (
        <div>
            <ol class="list-group list-group-numbered">
               
                {reversed.map((thisSubmission)=>(<SubmissionItem submission={thisSubmission} key={thisSubmission._id} openSubmissionModal={openSubmissionModal} token={token} serverAddress={serverAddress}/>))}
               
                </ol>
        </div>
    )
}
const SubmissionItem = ({submission, openSubmissionModal, token, serverAddress}) => {
    async function deleteSub(submissionId,token,serverAddress) {
        return fetch(serverAddress+'/admin/deleteSubmission/'+submissionId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
         
          }
          
        
        })
          .then(data => data.json())
       }
    //Handle delete of submission
    async function deleteSubmission(){
        if(window.confirm("Delete this submission?")){
            const response = await deleteSub(submission.id, token, serverAddress)
           
            
        }
        
       


        
    }
    
    return(

        <li class="list-group-item d-flex justify-content-between align-items-start" onClick={()=>{openSubmissionModal({id: submission._id})}}>
        <div class="ms-2 me-auto">
        <div class="fw-bold">{submission.email}</div>
        {submission.date}
        </div>
        <span class="badge bg-primary rounded-pill">{submission.time}</span>
        <button onClick={deleteSubmission} class="badge bg-danger ">Delete</button>

    </li>
    )
}


export default UserSubmissions
