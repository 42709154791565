import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'
import TileSm from '../../Tiles/TileSm';
import Radar from '../../Graphs/Radar';
const QuestionModal = ({questionModalOpen,setQuestionModalOpen, questionModalData, closeQuestionModal, submissions}) => {
 
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      
    
    
      function afterOpenModal() {

      }
    
      function closeModal() {
        console.log('Close modal')
        closeQuestionModal()
      }
    return (
        <div>
            
             
        <Modal
                isOpen={questionModalOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >   
                <button className="btn fas fa-arrow-circle-left" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, left : 0, fontSize : 25}}></button>

                <button className="btn btn-danger fas fa-times" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, right : 0}}></button>

                <br />
                <div style={{height : '75vh', width : '75vw'}}>
                    <h3>{questionModalData.question}</h3>
                    <QuestionStats questionModalData={questionModalData} submissions={submissions} />
                </div>

            </Modal>
            
        </div>
    )
}

const QuestionStats = (props) => {
    const [questionStats, setQuestionStats] = useState({answerCount :{},variables:{}})
    const [rangeData, setRangeData] = useState([])

    useEffect(() => {  
        //Generate statistics about that specific question
        var tempStats = {
            totalTimesAnswered : 0,
            totalTimesViewed : 0,
            answerCount : {},
            totalTime : 0,
            averageTime : 0,
            variables : {}


        }
        var tempRange= []

        props.submissions.map((submission)=>{
            //For each submission
            if(submission.userInputs){
                submission.userInputs.map((question)=>{
                    if(question.id === props.questionModalData.id){
                        tempStats.totalTimesViewed += 1
                        if(question.answered){
                            var split = question.time.split(':')
                            var inSeconds = parseInt(split[0])* 60 + parseInt(split[1])
                            tempStats.totalTimesAnswered += 1
                            tempStats.totalTime += inSeconds
                            if(tempStats.answerCount[question.answer]){
                                tempStats.answerCount[question.answer] += 1
                            }else{
                                tempStats.answerCount[question.answer] = 1
                            }

                            //Add all the variable that are affected
                            if(question.variablesAffected){
                                question.variablesAffected.map((variable)=>{
                                    if(tempStats.variables[variable.variable]){
                                        tempStats.variables[variable.variable] += variable.weight
                                    }else{
                                        tempStats.variables[variable.variable] = variable.weight

                                    }
                                })
                            }
                            
                        }
    
                    }
                })
            }
            
        })

        Object.keys(tempStats.variables).map((variable)=>{
            tempRange.push({variable: variable, 'Avg Value' : tempStats.variables[variable]})
        })
        setRangeData(tempRange)
        console.log(tempRange)
        
        console.log(tempStats)
        tempStats.averageTime = tempStats.totalTime/tempStats.totalTimesAnswered
        setQuestionStats(tempStats)
    },[ props.submissions, props.questionModalData])
    function format(time) {   
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + "h" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + "m" + (secs < 10 ? "0" : "");
        ret += "" + secs +'s';
        return ret;
    }
    var small = [{title: "Avg Time", number : format(questionStats.averageTime)},{title: "Total Time", number : format(questionStats.totalTime)},{title: "Completion Rate", number :((questionStats.totalTimesAnswered/questionStats.totalTimesViewed)*100).toFixed(2)+'%'}]

    return(<div>
          <div class="row row-cols-1 row-cols-md-3 g-4">
              {small.map((tile)=>(<TileSm tile={tile}/>))}

            </div>
            <div style={{height:400, width:400}}>

            <Radar data={rangeData} keys={['Avg Value']} indexBy={'variable'}/>
            </div>
            <br />
            <h2>Answers</h2>

            <ul class="list-group">

            {Object.keys(questionStats.answerCount).map((answer)=>(
               <Answer answer={answer} value = {questionStats.answerCount[answer]} />
            ))}
  
            </ul>
    </div>)

}

const Answer = (props) => {
    return(
        <li class="list-group-item d-flex justify-content-between align-items-center">
   {props.answer}
    <span class="badge bg-primary rounded-pill">{props.value}</span>
  </li>
    )
}


export default QuestionModal
