import React, { useState, useEffect } from 'react';
import { stringify } from 'query-string';
import { useParams } from 'react-router-dom';
import EditQuestion from './EditQuestion/EditQuestion';
import SideBar from './SideBar/SideBar';
import EditGeneral from './EditGeneral/EditGeneral';
import SurveySettings from './SurveySettings/SurveySettings';
const EditSurvey = ({token, serverAddress}) => {
   

       
    return (
        <div>


        <div className="row">
          <div style={{width : "25vw"}}>
              <SideBar />
  
          </div>
          <div style={{width : "75vw"}}>
      
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><EditQuestion serverAddress={serverAddress} token={token}/></div>
    <div class="tab-pane fade" id="dist" role="tabpanel" aria-labelledby="dist-tab"><EditGeneral serverAddress={serverAddress} token={token}/></div>
    <div class="tab-pane fade" id="set" role="tabpanel" aria-labelledby="set-tab"><SurveySettings serverAddress={serverAddress} token={token}/></div>

  </div>
  
            </div>
        </div>  
  
  
  
  
  
     </div>
  
    )
}
export default EditSurvey

