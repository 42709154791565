import React, { useState, useEffect } from 'react';

const EmailGeneral = ({token, serverAddress}) => {
    const [name, setName] = useState('')
    const [cursorPosition, setCursorPosition] = useState('')

    const [subject, setSubject] = useState('')
    const [beforeResults, setBeforeResults] = useState('')
    const [afterResults, setAfterResults] = useState('')
    const [update, setUpdate] = useState(0)


    async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status === 200){
                if(dataFromServer.data.email){
                    setName(dataFromServer.data.email.name)
                    setSubject(dataFromServer.data.email.subject)
                    setBeforeResults(dataFromServer.data.email.before)
                    setAfterResults(dataFromServer.data.email.after)
                }
                
        
            }
        }
       runGet()
        
      }, [ update ])
      async function updateSurvey(body) {
        return fetch(serverAddress+'/admin/addSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
      function getQueryVariable(variable)
      {
              var query = window.location.search.substring(1);
              //console.log(query)//"app=article&act=news_content&aid=160990"
              var vars = query.split("&");
              //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
              for (var i=0;i<vars.length;i++) {
                          var pair = vars[i].split("=");
                          //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
              if(pair[0] == variable){return pair[1];}
               }
               return(false);
      }

      async function submitEmail() {
        var emailBody = {
            name,
            subject,
            before : beforeResults.replace('\n','<br/>'),
            after : afterResults.replace('\n','<br/>')
        }
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'email' : emailBody
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
        
        }



       }


       




    return (
        <div>
            <h1>General Email Settings</h1>
            <br />
            <div class="input-group">
                    <span class="input-group-text">Email Sender Name (Your name or company)</span>
                    <input value={name} class="form-control" aria-label="With textarea" onChange={(e)=>{setName(e.target.value)}} />
                </div>
                <br />

            <div class="input-group">
                    <span class="input-group-text">Subject Line</span>
                    <input value={subject} class="form-control" aria-label="With textarea" onChange={(e)=>{setSubject(e.target.value)}} />
                </div>

                <br />
            <div class="input-group">
                    <span class="input-group-text">Before Condition Results</span>
                    <textarea value={beforeResults} class="form-control" aria-label="With textarea" onChange={(e)=>{setBeforeResults(e.target.value)}}></textarea>
                </div>
                <div dangerouslySetInnerHTML={{__html: beforeResults.replace(/(?:\r\n|\r|\n)/g, "<br />")}}></div>

                <br />

                <div class="input-group">
                    <span class="input-group-text">After Condition Results</span>
                    <textarea value={afterResults} class="form-control" aria-label="With textarea" onChange={(e)=>{setAfterResults(e.target.value)}}></textarea>
                </div>
                <div dangerouslySetInnerHTML={{__html: afterResults.replace(/(?:\r\n|\r|\n)/g, "<br />")}}></div>


                <br />

                <a className="btn btn-primary" onClick={()=>{submitEmail()}}>Save</a>
        </div>
    )
}

export default EmailGeneral
