import React, { useState, useEffect } from 'react';
import Radar from '../../../Graphs/Radar';
const VariableRadar = ({submissions}) => {
    const [data, setData] = useState([])
   
    
    useEffect(() => {
        //We will organize the variable radar data here
        var tempData = []
        var tempObj = {}
        var highestList = {}
          
        for(var x = 0;x<submissions.length;x++){
            var submission = submissions[x]
            Object.keys(submission.results).map((thisKey)=>{
                if(tempObj[thisKey]){
                    tempObj[thisKey] += submission.results[thisKey]

                }else{
                    tempObj[thisKey] =submission.results[thisKey]
                }
                if(highestList[thisKey]){
                    if(highestList[thisKey] <= submission.results[thisKey]){
                        highestList[thisKey] = submission.results[thisKey]
                    }

                }else{
                    highestList[thisKey] = submission.results[thisKey]
                }
                

            
            })
        }

        Object.keys(tempObj).map((thisVariable)=>{tempData.push({
            variable : thisVariable,
            "Avg Value": tempObj[thisVariable] / submissions.length,
        })})

        setData(tempData)
        
      }, [ submissions]);




    return (
        <div style={{overflow:'scroll'}}>
            <Radar data={data} keys={['Avg Value']} indexBy={'variable'} maximize={true}/>
        </div>
    )
}

export default VariableRadar
