import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import Modal from 'react-modal'

const Bar = ({data, keys, indexBy, openSubmissionModal, maximize}) => {
    const [modalIsOpen, setIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      
    function openModal() {
        setIsOpen(true);
      }
    
      function afterOpenModal() {

      }
    
      function closeModal() {
        setIsOpen(false);
      }
      if(!maximize){
        return(
          <div style={{height: 400,width: 400}}>

          <div style={{height: "100%",width:"100%"}}>
          <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}  />
          </div>
          </div>
        )
      }
    return (
        <div style={{height: 400,width: 400}}>

            <i className="fas fa-expand" onClick={openModal} style={{position: 'absolute', top: 0, right : 0, fontSize: 40}}></i>
        <div style={{height : '100%', width : '100%', }}>
        <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}  />

        </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="btn btn-danger fas fa-times" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, right : 0}}></button>
        <div style={{height : '75vh', width : '75vw'}}>
        <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}/>

        </div>
      </Modal>
     

        
        </div>
      
    )
}

const Graph = ({data,keys, indexBy, openSubmissionModal})=>{

    return( 
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 75,
            legend: 'Email',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        onClick={(data) => {
            //Here we can do so when a submission is clicked, we can get more information
            openSubmissionModal(data.data)
          
          }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />)
}
export default Bar
