import React, { useState, useEffect } from 'react';

const Filters = ({serverAddress, token, surveyId}) => {
    if(!surveyId){
        return(<div>
            Maybe add a submission before you try to add filters?
        </div>)
    }
    return (
        <div>
            <div class="accordion" id="filterAccordion">
  <div class="accordion-item">
    <h2 class="accordion-header" id="filterHeading">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters">
        <i className="fas fa-filter"></i>Filters
      </button>
    </h2>
    <div id="collapseFilters" class="accordion-collapse collapse show" aria-labelledby="filterHeading" data-bs-parent="#filterAccordion">
      <div class="accordion-body">

            <AllFilters serverAddress={serverAddress} token={token} surveyId={surveyId}/>





      </div>
    </div>
  </div>
  
</div>
        </div>
    )
}

const AllFilters = ({serverAddress, token, surveyId}) => {



    const [survey, setSurvey] = useState([])
    const [questions, setQuestions] = useState([])

    const [variables, setVariables] = useState([])
    const [filters, setFilters] = useState([{id: "ehaef", type : "not",  stat : {question : 'Question one', answer : 'This answer'}}])
    


    //Get extra data from server
    async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' :surveyId
          }
        })
          .then(data => data.json())
       }
    //Getting inital
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status == 200){
                console.log("Filters", dataFromServer)
                setSurvey(dataFromServer.data);
                if(dataFromServer.data.questions){
                    setQuestions(dataFromServer.data.questions)
                }
                if(dataFromServer.data.variables){
                    setVariables(dataFromServer.data.variables)
                }
               
                
        
            }
        }
       runGet()
        
      }, [ ]);

    const handleUpdate = (id, key, value) =>{
        var newKey = key.split('.')
        var newFilters = []
        filters.map((filter)=>{
            if(filter.id === id){
                if(newKey[1] && filter[newKey[0]]){
                    console.log("newKey", newKey)
                    console.log(filter)
                    filter[newKey[0]][newKey[1]] = value

                }else{
                    filter[newKey[0]] = value

                }
            }
            newFilters.push(filter)
        })
        console.log("New filters", newFilters)
        setFilters(newFilters)
        
    }
    return(
        <div>
            {filters.map((filter)=>(<OneFilter filter={filter} key={filter.id} handleUpdate={handleUpdate} variables={variables} questions={questions}/>))}
            <br />
            <button className ="btn btn-block btn-primary" onClick={()=>{setFilters([...filters,{id: Math.random(), type : '', stat : {}}])}}>Add Filter</button>


        </div>

    )

}


const OneFilter = ({filter, handleUpdate, questions, variables}) =>{

    return(
        <form class="row g-3">

        <div class="card">
  <div class="card-header">
  <div class="col-auto g-3">
    <select class="form-select" aria-label="Type" onChange={(e)=>{handleUpdate(filter.id,'type',e.target.value)}}>
    <option selected disabled hidden>Filter Type</option>
    <option value="question">Question</option>
    <option value="time">Time</option>
    <option value="variable">Variable</option>
    </select>
  </div>
  </div>
  <div class="card-body">
    <blockquote class="blockquote mb-0">
 

  <FilterType type={filter.type} filter={filter} questions={questions} variables={variables} handleUpdate={handleUpdate}/>
         
    </blockquote>
  </div>
</div>
</form>

       
    )
}

const FilterType =(props) =>{
    switch (props.type){
        case 'question': return(<QuestionFilterType filter={props.filter} questions={props.questions} handleUpdate={props.handleUpdate}/>)
        case 'variable': return(<VariableFilterType filter={props.filter} variables={props.variables} handleUpdate={props.handleUpdate}/>)

        default : return(<h1>No Filter</h1>)
    }

}


const QuestionFilterType =(props) =>{
    const [selectedQuestion, setSelectedQuestion] = useState({options: []})

   

    return( 
        <div class="row g-3 align-items-center">
    <div class="col-auto">
    <select class="form-select" aria-label="Type" onChange={(e)=>{
        props.questions.map((question)=>{if(question.question === e.target.value){setSelectedQuestion(question);
            props.handleUpdate(props.filter.id, 'stat.question', question.question);
            //Need to set the question ID to be able to easily find it
            props.handleUpdate(props.filter.id, 'stat.id', question.id);

        }});
        }}>
    <option selected disabled hidden>Which Question</option>
    {props.questions.map((question)=>(<option >{question.question}</option>))}
  
    </select>

  </div>
  <QuestionTypeAnswer selectedQuestion={selectedQuestion} handleUpdate={props.handleUpdate} filter={props.filter}/>


  </div>
  )
  
  

}

const QuestionTypeAnswer = (props) =>{
    switch (props.selectedQuestion.type){
        case 'shortAnswer' : return(   
            <p>Short answer questions are currently not supported</p>
       )
       case undefined : return(null)
       default : return( <div class="col-auto">
       <select class="form-select" aria-label="Type" onChange={(e)=>{props.handleUpdate(props.filter.id,'stat.answer',e.target.value)}}>
       <option selected disabled hidden>Answer</option>
       {props.selectedQuestion.options.map((option)=>(<option>{option.option}</option>))}
       </select>  </div>
       )
    }

}

const VariableFilterType =(props) =>{
    
    return( 
        <div class="row g-3 align-items-center">
    <div class="col-auto">
    <select class="form-select" aria-label="Type">
    <option selected disabled hidden>Which Variable</option>
    {props.variables.map((variable)=>(<option >{variable}</option>))}
    
    </select>
  </div>
  <div class="col-auto">
    <select class="form-select" aria-label="Type">
    <option value="greater">Greater than</option>
    <option value="equal">Equal To</option>
    <option value="lesser">Lesser Than</option>
    </select>
  </div>
  <div class="col-auto">
  <input class="form-control" type='number' />  </div>
  </div>
  )


}

const AddFilter = () => {

}

export default Filters
