import React, { useState, useEffect } from 'react';
import { stringify } from 'query-string';
import { useParams } from 'react-router-dom';
import Submissions from './Submissions';
import ModalManager from './ModalManager';

const GetSubmissions = ({serverAddress, token}) => {
    const [submissions, setSubmissions] = useState([])
    const [sortedData, setSortedData] = useState([])
    const [collectors, setCollectors] = useState([])
    const [update, setUpdate] = useState(0)



    async function getSurvey() {
      return fetch(serverAddress+'/survey/get', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token' : token,
          'surveyid' : getQueryVariable('id')
        }
      })
        .then(data => data.json())
     }

      //Getting inital
      useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status == 200){
                if(dataFromServer.data.collectors){
                    setCollectors(dataFromServer.data.collectors)
                }
                        
            }
        }
       runGet()
        
      }, [ update ]);
    async function getSubmissions() {
        return fetch(serverAddress+'/admin/submissions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
         
          },
          body: JSON.stringify({
            'survey' : getQueryVariable('id')
          })
        })
          .then(data => data.json())
       }
       useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSubmissions();
            console.log(dataFromServer)
            if(dataFromServer.status === 200){
                setSubmissions(dataFromServer.data);
                setSortedData(dataFromServer.data)
               
            }
        }
       runGet()
        
      }, [ update ]);

     
    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }



    return (
   <div>
       <ModalManager 
        submissions={submissions} 
        sortedData = {sortedData}
        setSortedData = {setSortedData}
        collectors={collectors}
        serverAddress={serverAddress}
        token={token}
        update={update}
        setUpdate={setUpdate} />
   </div>          
  
    )
}




export default GetSubmissions

