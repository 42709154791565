import React, { useState, useEffect } from 'react';

const Select = ({question, handleUpdate}) => {

    async function selectedOption(selected) {
        //Since unlike multiple choice questions, select ones only let use use onChange on the head. So there is so way to directly pass data from the option selected to the handleUpdate function
        
        question.options.map((option)=>{

            if(option.id === parseFloat(selected)){

                handleUpdate(option.value, option.variables,option.option)

            }
        })
    }
    if(question.required){
        console.log("This question is required")
        return(
            <div class="form-group">
                    <div class="row">
                    <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
                    <h2>{question.question}</h2>
        
                    <select class="form-select" aria-label="Default select example" onChange={(e)=>{selectedOption(e.target.value)}} required>
                    <option value='' disabled selected hidden>Select an option</option>
    
                    {question.options.map((option)=>(<SelectOption option={option} key={option.id} />))}
        
                    </select>
                    
                    </div>
                  </div>
            )
    }
    return(
    <div class="form-group">
            <div class="row">
            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
            <h2>{question.question}</h2>

            <select class="form-select" aria-label="Default select example" onChange={(e)=>{selectedOption(e.target.value)}}>
            <option value='none' selected disabled hidden>Select an option</option>

            {question.options.map((option)=>(<SelectOption option={option} key={option.id} />))}

            </select>
            
            </div>
          </div>
    )

}
const SelectOption = ({option}) => {
    return(
      <option value={option.id}>{option.option}</option>
   
    )
}
export default Select
