import PropTypes from 'prop-types';
import React, { useState } from 'react';


const Register = ({setToken, serverAddress, logo}) => {
  async function registerUser(credentials) {
    return fetch(serverAddress+'/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [warnings, setWarnings] = useState([]);

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await registerUser({
      email,
      password
    });
    if(token.status == 201){
        setToken(token);
        
        setWarnings([{message: token.message,type:"alert alert-success alert-flush"}])

        console.log("This is the right information")
        window.location.replace('/login')

    }else{

        setWarnings([{message: token.reason ,type:"alert alert-danger alert-flush"}])
    }
  }
    return (
<div className="text-center container">

            {warnings.map((warning)=>( <div className={warning.type} role="alert">
    <strong>Heads up!</strong> {warning.message}
        </div>))}

<main class="form-signin">
  <br />
  <form onSubmit={handleSubmit}>
  <img src={logo} height={200} width="auto" />
    <h1 class="h3 mb-3 fw-normal">Please enter your information below</h1>

    <div class="form-floating">
      <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
      <label for="floatingInput">Email address</label>
    </div>
    <div class="form-floating">
      <input type="password" class="form-control" id="floatingPassword" placeholder="Password" onChange={e => setPassword(e.target.value)} />
      <label for="floatingPassword">Password</label>
    </div>

 
    <button class="w-100 btn btn-lg btn-primary" type="submit">Register</button>
    <p class="mt-5 mb-3 text-muted">© 2021</p>
  </form>
</main>

</div>          
    )
}
Register.propTypes = {
    setToken: PropTypes.func.isRequired
  }
export default Register