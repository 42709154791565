import React, { useState, useEffect } from 'react';
import TileSm from '../../Tiles/TileSm'
import VariableRadar from './Graphs/VariableRadar'
import DomainPie from './Graphs/DomainPie'
import VariableTimeBar from './Graphs/VariableTimeBar';

const SubmissionsDashboard = (props) => {
    function format(time) {   
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + "h" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + "m" + (secs < 10 ? "0" : "");
        ret += "" + secs +'s';
        return ret;
    }
    const [totalQuestionStats, setTotalQStats] = useState({processSub:{}})

    var small = [{title: "Submissions", number : props.submissions.length},{title: "Visits", number : 'N/A'},{title: "Avg Q Time", number : format(totalQuestionStats.avgTimePerQ)},{title: "Avg Time", number : format(totalQuestionStats.avgTime)},{title: "% Finished", number :((totalQuestionStats.processSub.completed/totalQuestionStats.processSub.total)*100).toFixed(2)+'%'}]
    const [questionStats, setQuestionStats] = useState({})

    //Now we need to calculate average question time
    useEffect(() => {  
        var tempQStats = {}
        var processSub = {
            completed : 0,
            total : 0
        }
        var workingSubmissions = 0
        props.submissions.map((submission)=>{
            //For each submission
            if(submission.userInputs){
                workingSubmissions += 1
                var doneAll = true
            submission.userInputs.map((question)=>{
                if(!question.answered){
                    doneAll = false
                    processSub.total += 1

                }else{
                var split = question.time.split(':')
                var inSeconds = parseInt(split[0])* 60 + parseInt(split[1])
                if(tempQStats[question.id]){
                    tempQStats[question.id].totalTime += inSeconds
                    tempQStats[question.id].timeCount += 1
                    //Check if our answer has been answered yey
                    if(tempQStats[question.id].answer[question.answer]){
                        tempQStats[question.id].answer[question.answer] += 1
                    }else{
                        //If the answer does not exist then we add it
                        tempQStats[question.id].answer[question.answer] = 1
                    }



                }else{
                    var tempAnswer = {}
                    tempAnswer[question.answer] = 1
                    tempQStats[question.id] = {
                        totalTime : inSeconds,
                        timeCount : 1,
                        answer : tempAnswer

                        

                    }
                }
            }
            })


            if(doneAll){
                processSub.total += 1
                processSub.completed +=1
            }
            }
        })
        setQuestionStats(tempQStats)

        var totalQStats = {
            totalTime : 0,
            avgTime : 0,
            timeCount : 0,
            processSub

        }

        //Now we will get overall question statistics
        Object.keys(tempQStats).map((thisQ)=>{
            totalQStats.totalTime += tempQStats[thisQ].totalTime
            totalQStats.timeCount += tempQStats[thisQ].timeCount
        })
        totalQStats.avgTimePerQ = parseInt(totalQStats.totalTime/totalQStats.timeCount)
        
        var totalTimeAll = 0
        var totalTimeCount = 0
        if(props.submissions){
            props.submissions.map((submission)=>{
                if(submission.time){
                    var split = submission.time.split(':')
                    var inSeconds = parseInt(split[0])* 60 + parseInt(split[1])
                    totalTimeAll += inSeconds
                    totalTimeCount += 1

                }
              
            })
        }
        totalQStats.avgTime = parseInt(totalTimeAll/totalTimeCount)

        
      
        setTotalQStats(totalQStats)
        

    }, [ props.submissions]);
    
    
    
    return (
        <div>
            <div class="row row-cols-1 row-cols-md-5 g-4">
              {small.map((tile)=>(<TileSm tile={tile}/>))}


                </div>

                <br />

                <div class="row row-cols-1 row-cols-md-3 g-7">
             <div class="col">
                    <div class="card" style={{alignItems :'center', overflowX:'scroll'}}>
                    <h5 class="card-title">Variable Score Per Submission</h5>

                        <VariableTimeBar submissions={props.submissions} openSubmissionModal={props.openSubmissionModal} />
                    </div>
                </div>

                <div class="col">
                    <div class="card" style={{alignItems :'center',overflowX:'scroll'}}>
                    <h5 class="card-title">Variable Radar</h5>

                        <VariableRadar submissions={props.submissions} openSubmissionModal={props.openSubmissionModal}/>
                    </div>
                </div>

                <div class="col">

                    <div class="card" style={{alignItems :'center',overflowX:'scroll'}}>
                    <h5 class="card-title">Email Domains</h5>

                        <DomainPie submissions={props.submissions} openSubmissionModal={props.openSubmissionModal}/>
                    </div>
                </div>

</div>
              


            
        </div>
    )
}

export default SubmissionsDashboard
