import React from 'react'

const MultipleChoice = ({question, handleUpdate}) => {
    return(
<fieldset class="form-group">
            <div class="row">
            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
            <h2>{question.question}</h2>

              
              <div class="col-sm-10">
                {question.options.map((option, index)=>(<MultipleChoiceOption index={index} option={option} handleUpdate={handleUpdate} id = {question.id} key={option.id} question={question}/>))}
               
                
              </div>
            </div>
          </fieldset>
    )
   

}

const MultipleChoiceOption = ({question, option, handleUpdate, id, index}) => {
  if(index === 0 && question.required){
    return(
      <div class="form-check">
      
  <input class="form-check-input" type="radio" id={option.id} name={id} value={option.value} onChange={(e)=>{handleUpdate(e.target.value, option.variables,option.option)}} required/>
  <label class="form-check-label" for={option.id}>
    {option.option} 
  </label>
</div>
  )
  }
  return(
    <div class="form-check">
    
<input id={option.id} class="form-check-input" type="radio" name={id} value={option.value} onChange={(e)=>{handleUpdate(e.target.value, option.variables,option.option)}} />
<label class="form-check-label" for={option.id}>
  {option.option} 
</label>
</div>
)
   
}
export default MultipleChoice
