import React, { useState, useEffect } from 'react';

import SubmissionsDashboard from './SubmissionsDashboard/SubmissionsDashboard';
import Filters from './Filters/Filters';
const Dashboard = (props) => {
    const [dist, setDist] = useState('')
    const [sorted, setSorted] = useState([])
    const [parseFilters, setParseFilters] = useState([])

    useEffect(() => {
        if(dist != ''){
            var tempSorted = []
            for(var i=0;i<props.submissions.length;i++){
                    if(props.submissions[i].collector === dist){
                        tempSorted.push(props.submissions[i])
                    }
            }

            setSorted(tempSorted)
        }else{
            setSorted(props.submissions)
        }
        
        
      }, [ dist, props.submissions]);


    return (
        
        <div>
            <h1>Dashboard</h1>

            <br />

            <Filters parseFilters={parseFilters} setParseFilters={setParseFilters} serverAddress={props.serverAddress} token={props.token} surveyId ={ props.submissions[0] ? props.submissions[0].surveyId : false}/>
            <br />

            <h2>Sort By Collector</h2>
            <select class="form-select" aria-label="Default select example" onChange={(e)=>{setDist(e.target.value)}}>
            <option value = '' selected>All</option>

            {props.collectors.map((collector)=>(  <option value={collector.id}>{collector.name}</option>
            ))}
            </select>

            <br />
            <SubmissionsDashboard submissions={sorted} openSubmissionModal={props.openSubmissionModal}/>

            <br />
         

        </div>
    )
}

export default Dashboard
