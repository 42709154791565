import React, { useState, useEffect } from 'react';
import Bar from '../../../Graphs/Bar';

const VariableTimeBar = ({submissions, openSubmissionModal}) => {
    const [data, setData] = useState([])
    const [unique, setUnique] = useState([])

    useEffect(() => {
        //We will organize the Variable Over Time bar graph
        var tempData = []
        var allResults = {}
        submissions.map((submission)=>{
            tempData.push({email : submission.email, ...submission.results, id : submission._id})
            allResults = {...allResults,...submission.results}
            
        })
        setUnique(Object.keys(allResults))
        setData(tempData)
        
    },[submissions])
    return (
        <div style={{overflowX :'scroll'}}>
            <Bar data={data} keys={unique} indexBy={'id'} openSubmissionModal={openSubmissionModal} maximize={true}/>
        </div>
    )
}

export default VariableTimeBar
