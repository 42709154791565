import React from 'react'

const ShortAnswer = ({question, handleUpdate}) => {
    if(question.required){
        return(
            <fieldset class="form-group">
                <div class="row">
                <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
                <h2>{question.question}</h2>
    
                <div class="mb-3">
                    <textarea class="form-control" id="inputQuestion" rows="3" onChange={(e)=>{handleUpdate(undefined, undefined,e.target.value)}} required></textarea>
                </div>
                </div>
              </fieldset>
        )
    }
    return(
        <fieldset class="form-group">
            <div class="row">
            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
            <h2>{question.question}</h2>

            <div class="mb-3">
                <textarea class="form-control" id="inputQuestion" rows="3" onChange={(e)=>{handleUpdate(undefined, undefined,e.target.value)}}></textarea>
            </div>
            </div>
          </fieldset>
    )
    

}

export default ShortAnswer
