import React from 'react'

const ShowVariable = ({variable, deleteComponent}) => {
    return (
      <li class="list-group-item">
       
  {variable}
  <span class="position-absolute top-50 start-100 translate-middle badge rounded-pill bg-danger" onClick={()=>{deleteComponent({variable : variable})}}>
    X
  </span>
  </li>

    )
}

export default ShowVariable
