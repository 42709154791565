const Header = ({logo, logout, token}) => {
 
    return (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src={logo} height = "50px" weight = "auto" onClick={()=>window.location.replace('/')}/>
  

           </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            
            <Links token={token} logout = {logout} />
          
           
        </div>

      </div>
    </nav>
            
    )
}
const Links = ({token, logout}) => {
  if(token){
    return(            <ul class="navbar-nav">

      <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
    <a class="nav-link active" href="/admin" >
   Admin
  </a>
    </li>
    <li class="nav-item dropdown">
  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
   Account
  </a>
 
  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <li><a class="dropdown-item" onClick={logout}>Logout</a></li>
  </ul>
</li></ul>
)
  }
  return(    <ul class="navbar-nav">

  <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        <li className="nav-item">
<a class="nav-link disabled" href="/about" >
About
</a>
</li>
<li className="nav-item">
<a class="nav-link active" href="/login" >
Login
</a>
</li>
<li className="nav-item">
<a class="nav-link active" href="/register" >
Register
</a>
</li>

</ul>)

}
export default Header
