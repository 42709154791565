import React, { useState, useEffect } from 'react';
import DisplaySurvey from './DisplaySurvey/DisplaySurvey';
import { Helmet } from "react-helmet"

const Survey = ({serverAddress, token, backupAddress}) => {
    const [questions, setQuestions] = useState([])
    const [variablesAvail, setVariablesAvail]= useState([])
    const [survey, setSurvey] = useState({design: {metadata:{}}});
    
    

    async function getSurvey(address) {
        return fetch(address+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
         
          
       }

           //Getting inital
           useEffect(() => {
               
            async function runGet() {
                var dataFromServer = await getSurvey(serverAddress).catch(async function(){
                    return await getSurvey(backupAddress)
                })
                if(!dataFromServer.status){
                    dataFromServer = await getSurvey(backupAddress)

                }
             
                if(dataFromServer && dataFromServer.status == 200){
                    if(!dataFromServer.data.design){
                        var tempSurvey = dataFromServer.data
                        tempSurvey.design = {metadata : {title: "openSurvey", desc: "The survey software build to fill the gaps."}}
                        setSurvey(tempSurvey)
                    }else{
                     
                        setSurvey(dataFromServer.data);
                        //If metadata exists
                       /* if(dataFromServer.data.design.metadata){
                            if(dataFromServer.data.design.metadata.title != ''){
                                document.title = dataFromServer.data.design.metadata.title
    
                            }
                            if(dataFromServer.data.design.metadata.favicon != ''){
                                document.querySelector("link[rel*='icon']").href = dataFromServer.data.design.metadata.favicon
    
                            }
                            
                            if(dataFromServer.data.design.metadata.desc != ''){
                                document.querySelector("meta[name*='description']").content = dataFromServer.data.design.metadata.desc
    
                            }

                        }*/
                        

                    }
                    if(dataFromServer.data.questions){
                        setQuestions(dataFromServer.data.questions)
                    }
                    if(dataFromServer.data.variables){
                        setVariablesAvail(dataFromServer.data.variables)
                    }
                    if(!dataFromServer.data.design){
                        var tempSurvey = survey
                        tempSurvey.design = {}
                        setSurvey(tempSurvey)
                    }
                   
                    
            
                }
            }
           runGet()

            
          }, [ ]);



          function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }
    /*if(!questions[0]){
        return (
            <div className='container'>
                <Helmet>
                    <title>{survey.design.metadata.title}</title>
                    <link rel="icon" href={survey.design.metadata.favicon}/>
       
                    <meta
                    name="description"
                    content={survey.design.metadata.desc}
                    />
    
                </Helmet>
                <div className="text-center">
                <div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
    </div>
               
                
            </div>
        )
    }
    else*/ if(survey.design && survey.design.metadata){
        return (
            <div className='container'>
                <Helmet>
                    <title>{survey.design.metadata.title}</title>
                    <link rel="icon" href={survey.design.metadata.favicon}/>
       
                    <meta
                    name="description"
                    content={survey.design.metadata.desc}
                    />
                     <title>openSurvey</title>


                    <meta
                    name="description"
                    content="The survey software build to fill the gaps."
                    />
                    <meta
                    property="og:image"
                    content="%PUBLIC_URL%/logo.svg"
                    />
    
                </Helmet>
                <div className='container'>
         
         <h2>{survey.design.title}</h2>

         <div dangerouslySetInnerHTML={{__html: survey.design.before}}></div>


         <DisplaySurvey questions={questions} setQuestions={setQuestions} serverAddress={serverAddress} backupAddress={backupAddress} token ={token} survey={survey}/>
         

        
         
     </div>
               
                
            </div>
        )
    }
   
    return (
        <div className='container'>
         
            <h2>{survey.design.title}</h2>
            <div dangerouslySetInnerHTML={{__html: survey.design.before}}></div>


            <DisplaySurvey questions={questions} setQuestions={setQuestions} backupAddress={backupAddress} serverAddress={serverAddress} token ={token} survey={survey}/>
            

           
            
        </div>
    )
}


export default Survey
