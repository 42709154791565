import React, { useState, useEffect } from 'react';

const Collectors = ({serverAddress, token}) => {
    const [dist, setDist] = useState('')
    const [collectors, setCollectors] = useState([])
    const [update, setUpdate] = useState(0);

    async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
    async function updateSurvey(body) {
        return fetch(serverAddress+'/admin/addSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
       async function deleteSurveyComponent(body) {
        return fetch(serverAddress+'/admin/deleteSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
    async function submitCollector(collector) {
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'collector' : collector
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
                setDist('')
        
        }



       }
       async function removeCollector(collector) {
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'collector' : collector
        }
        const dataFromServer = await deleteSurveyComponent(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
        
        }



       }



            //Getting inital
            useEffect(() => {
                async function runGet() {
                    const dataFromServer = await getSurvey();
                    if(dataFromServer.status == 200){
                        if(dataFromServer.data.collectors){
                            setCollectors(dataFromServer.data.collectors)
                        }
                                
                    }
                }
               runGet()
                
              }, [ update ]);

       function getQueryVariable(variable)
       {
               var query = window.location.search.substring(1);
               //console.log(query)//"app=article&act=news_content&aid=160990"
               var vars = query.split("&");
               //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
               for (var i=0;i<vars.length;i++) {
                           var pair = vars[i].split("=");
                           //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
               if(pair[0] == variable){return pair[1];}
                }
                return(false);
       }

    return (
        <div>
            <h1>Collectors</h1>
            <p>Collectors allow for you to categorize and sort submissions </p>

            <div class="form-floating mb-3">
            <div class="input-group mb-3">

                <input type="email" class="form-control" id="floatingInput" value={dist} placeholder="Collector Name" onChange={(e)=>{setDist(e.target.value)}}/>
                <button class="btn btn-outline-primary" type="button" id="button-addon2" onClick={()=>{submitCollector(dist)}}>Submit</button>
          </div>
            </div>
            <ul class="list-group list-group-flush">    
            
            {collectors.map((collector)=>(<DistOption collector={collector} removeCollector={removeCollector} getQueryVariable={getQueryVariable}/>))}
            </ul>
            
            
        </div>
    )
}


    const DistOption = ({collector, removeCollector, getQueryVariable}) => {
        return(
            <div>
                  <li class="list-group-item">   {collector.name} - Link: https://survey.evaluable.co/{getQueryVariable('id')}?dist={collector.id}  <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>{removeCollector(collector)}}>Delete</button>
</li>  


            </div>

        )

    }



export default Collectors
