import React, { useState, useEffect } from 'react';

const SideBar = ({allPages, selectedPage, setSelectedPage}) => {
  const [expanded, setExpanded] = useState(false)

    return (
      <nav class="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#fab8f6"}}>
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Submissions</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      {allPages.map((page)=>(<PageLink page={page} selectedPage={selectedPage} setSelectedPage={setSelectedPage} expanded={expanded}/>))}


      </ul>
     
    </div>
  </div>
</nav>
 
        
        
        
    )
}
const PageLink = ({page, selectedPage, setSelectedPage,expanded}) => {

  if(selectedPage === page.name){
    return( <li class="nav-item" onClick={()=>{setSelectedPage(page.name)}} >

    <a href="#" class="nav-link link-dark active">
      <i className={page.icon}></i> {page.name}
      
    </a>
  </li>)
  }
  return(
  <li class="nav-item" onClick={()=>{setSelectedPage(page.name)}}>
              <a href="#" class="nav-link link-dark">
                <i className={page.icon}></i> {page.name}
                
              </a>
            </li>
  )
}


export default SideBar
