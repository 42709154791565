import React, { useState, useEffect } from 'react';
import { findDOMNode } from 'react-dom';
import Survey from "../Survey"
import axios from 'axios'

//Question Types Components
import MultipleChoice from './QuestionTypes/MultipleChoice';
import Range from './QuestionTypes/Range'
import ShortAnswer from './QuestionTypes/ShortAnswer';
import Select from './QuestionTypes/Select'
import { isCompositeComponent } from 'react-dom/test-utils';

const DisplaySurvey = ({questions, serverAddress, token, survey, backupAddress}) => {
    const [score, setScore] = useState({});
    const [varScores, setVarScore] = useState({});
    const [userInputs, setUserInputs] = useState([]);
    const [email, setEmail] = useState('');
    const [updateScore, setUpdateScore] = useState(0);

    //How fast the user fills everything out
    const [startTime, setStartTime] = useState(0);
    const [tempTime, setTempTime] = useState(0);

    const [endTime, setEndTime] = useState(0);

    const [userCountry, setUserCountry] = useState({countryName: '', countryCode : ''});

    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            setUserCountry(data);
        }).catch((error) => {
            console.log(error);
        });
    };
    //Get location
    useEffect(() =>{
        getGeoInfo()
    },[])

    //Set possible user inputs
    useEffect(() =>{
        var userInputOptions = []
        for(var i =0;i<questions.length;i++){
            var thisQuestionObj = {
                question : questions[i].question,
                answered : false,
                id : questions[i].id,
                type : questions[i].type,
                answer : undefined,
                variablesAffected : undefined,
                timeAnswered : 0
            }
            userInputOptions.push(thisQuestionObj)
        }
        setUserInputs(userInputOptions)
        //Questions just loaded, its time to start the counter
        var timeRn = window.performance.now()
        setStartTime(timeRn)
        setTempTime(timeRn)
        


  },[ questions ])



    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }

    async function postSubmission(body, address) {
        return fetch(address+'/survey/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'surveyid' : getQueryVariable('id')
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
       function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      }

       //Updating the variables
      const sendSubmission = (event) => {   
        //Prevent default form submission
        event.preventDefault();


        async function runSubmit(){
            document.getElementById('submitButton').disabled = true;
    
            var updateBody = {
                'results' : varScores,
                'email' : email,
                'collector' : getQueryVariable('dist'),
                'userInputs' : userInputs,
                'time' : millisToMinutesAndSeconds(window.performance.now()-startTime),
                'location' : userCountry
            }
            const dataFromServer = await postSubmission(updateBody, serverAddress).catch(async function(){
                return await postSubmission(updateBody, backupAddress)
            })
        
                if(dataFromServer.status == 201){
                    if(survey.design.redirect && survey.design.redirect != ''){
                        window.location.replace(survey.design.redirect)
    
                    }else{
                        window.location.reload()
    
                    }
    
            
            }
    
        }
        runSubmit()
        


       }
    
    useEffect(() =>{
        var allQuestions = Object.keys(score)
        var tempVarScores = {}
        for(var i =0;i<allQuestions.length;i++){
            var allvars = Object.keys(score[allQuestions[i]])
            for(var x =0;x<allvars.length;x++){
                if(tempVarScores[allvars[x]]){
                    tempVarScores[allvars[x]] +=  score[allQuestions[i]][allvars[x]]

                }else{
                    tempVarScores[allvars[x]] =  score[allQuestions[i]][allvars[x]]

                }

            }


        }
        setVarScore(tempVarScores)


  },[ updateScore ])


    return (
        <div className="container">
        <div style={{width:"60vw", margin:"0 auto"}}>
            <form onSubmit={sendSubmission} >
                
                   
            {questions.map((question)=>(<Question question={question} score={score} setScore={setScore} updateScore ={updateScore} setUpdateScore={setUpdateScore} setUserInputs ={setUserInputs} userInputs={userInputs} startTime={startTime} tempTime={tempTime} setTempTime={setTempTime}/>))}
           
            <div dangerouslySetInnerHTML={{__html: survey.design.after}}></div>

            <div class="d-grid gap-2">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{setEmail(e.target.value)}} required/>
                <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>                
            <button type="submit" class="btn btn-primary" id="submitButton">Submit</button>
            </div>
            </form>

        </div>
        </div>
    )
}



const Question = ({question, score, setScore,updateScore, setUpdateScore, setUserInputs, userInputs, startTime, tempTime, setTempTime}) => {
    
    var values = {}
    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      }
    const handleUpdate = async (value, newVars, questionValue) => {
        //Update user input values
        var userInputOptions = []
        for(var x = 0;x<userInputs.length; x++){
            //If the question matches the object "Question", then update it.
            //Information collected should be [answered, timestamp, answer, question, questionId]
            var thisQuestionObj
            if(userInputs[x].id === question.id){

                //If we are doing based on the question
                if(question.variables.length > 0){
                    //For each variable, multiply
                    var tempVariablesAffected = []
                    for(var j = 0; j<question.variables.length; j++){
                        //Create copy of the object
                        var newQ = Object.keys(question.variables[j]).map((thisKey)=> {return question.variables[j][thisKey]})

                        var newObj = {
                            weight : newQ[2],
                            variable:  newQ[1],
                            id : newQ[0]
                        }
                        newObj.weight *= value





                        tempVariablesAffected.push(newObj)
                    
                    }
                 
                    thisQuestionObj = {
                        question : userInputs[x].question,
                        answered : true,
                        id : userInputs[x].id,
                        type : userInputs[x].type,
                        answer : questionValue,
                        value,
                        variablesAffected : undefined,
                        timeAnswered : millisToMinutesAndSeconds(window.performance.now()-startTime),
                        time : millisToMinutesAndSeconds(window.performance.now()-tempTime),
                    }

                }else{
                    
                    thisQuestionObj = {
                        question : userInputs[x].question,
                        answered : true,
                        id : userInputs[x].id,
                        type : userInputs[x].type,
                        answer : questionValue,
                        value : value,
                        variablesAffected : undefined,
                        timeAnswered : millisToMinutesAndSeconds(window.performance.now()-startTime),
                        time : millisToMinutesAndSeconds(window.performance.now()-tempTime),
                    }

                }
               
            }else{
                thisQuestionObj = {
                    question : userInputs[x].question,
                    answered : userInputs[x].answered,
                    id : userInputs[x].id,
                    type : userInputs[x].type,
                    answer : userInputs[x].answer,
                    variablesAffected : userInputs[x].variablesAffected,
                    timeAnswered : userInputs[x].timeAnswered,
                    time : userInputs[x].time,


                }
            }
               
            userInputOptions.push(thisQuestionObj)
            
        }
        setUserInputs(userInputOptions) 
        setTempTime(window.performance.now())


      
          //If this question does affect variables
          if(newVars || question.variables){
            //Updating Variables
            var placeholder = newVars
            
            if(question.variables.length > 0){
                placeholder = question.variables


            }
            

            for(var i =0;i<placeholder.length ;i++){
            
                values[placeholder[i].variable] = value * placeholder[i].weight
            }
          
            var tempScore = score
            tempScore[question.id] = values

            setScore(tempScore)
            setUpdateScore(updateScore + 1)
        }

    }
  
    switch (question.type){
        case 'multipleChoice':  return(
            <div>
            <MultipleChoice key={question.id} question={question} handleUpdate={handleUpdate}/>
            <br />

            </div>
        )

        case 'select': return(
            <div>
            <Select key={question.id} question={question} handleUpdate={handleUpdate}/>
            <br />

            </div>
        )
    
        case 'shortAnswer': return (<div>
            <ShortAnswer key={question.id} question = {question} handleUpdate={handleUpdate} />
        </div>)
    
    
        default:  return(
            <div>
            <MultipleChoice key={question.id} question={question} handleUpdate={handleUpdate}/>
            <br />

            </div>
        )
    }
  
    
}


  

export default DisplaySurvey
