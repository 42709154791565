import React, { useState, useEffect } from 'react';

const PostSubEmail = ({serverAddress, token}) => {
    const [editCondition, setEditCondition] = useState({result : '', range0 : '', range1 : '', surveyId : getQueryVariable('id'), variable : ''})
    const [range0, setRange0] = useState('')
    const [range1, setRange1] = useState('')
    const [result, setResult] = useState('')
    const [variable, setVariable] = useState('')
    const [conditions, setConditions] = useState([])
    const [variablesAvail, setVariablesAvail] = useState([])
    const [update, setUpdate] = useState(0)


    useEffect(() => {
        setEditCondition({
            result,
            range0,
            range1,
            variable,
            id : editCondition.id,
            surveyId :editCondition.surveyId,
        })
        
      }, [range0,range1,result,variable]);


      //Get Survey components

      async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
      useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status == 200){
            
                if(dataFromServer.data.variables){
                    setVariablesAvail(dataFromServer.data.variables)
                }
               
            }
        }
       runGet()
        
      }, [ update ]);





    async function getSubmissions() {
        return fetch(serverAddress+'/admin/conditions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
         
          },
          body: JSON.stringify({
            'survey' : getQueryVariable('id')
          })
        })
          .then(data => data.json())
       }
       useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSubmissions();
            if(dataFromServer.status === 200){
                setConditions(dataFromServer.data);
                
        
            }
        }
       runGet()
        
      }, [ update ]);

      async function removeCondition(body) {
        return fetch(serverAddress+'/admin/remove/condition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
      async function createCondition(body) {
        return fetch(serverAddress+'/admin/create/condition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }

        //We want to edit this condition
       async function weWantEditCondition(condition) {
        if(window.confirm("Are you sure you want to edit this condition?")){
        
         setEditCondition(condition)
         setRange0(condition.range0)
         setRange1(condition.range1)
         setResult(condition.result)
         setVariable(condition.variable)
        }
     
     

    }
      //Submit/update condition

      //This is when we are adding a new question
    async function submitCondition() {
       
        
        const dataFromServer = await createCondition(editCondition);
            if(dataFromServer.status == 201){
                setEditCondition({result : '', range0 : '', range1 : '', surveyId : getQueryVariable('id'), variable : ''})
                setRange0('')
                setRange1('')
                setResult('')
                setVariable('')
                setUpdate(update+1)
        
            }

       }
     
        //We want to delete condition
    async function deleteCondition(condition) {
       
        if(window.confirm("Are you sure you want to delete this condition?")){

        const dataFromServer = await removeCondition(condition);
            if(dataFromServer.status == 201){
                setUpdate(update+1)
            }
        }

       }
    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }


  

    return (
        <div>
            <h1>
                Post Submission Email
            </h1>
            <ConditionEdit  variablesAvail={variablesAvail} editCondition={editCondition} setRange0={setRange0} setRange1={setRange1} setResult={setResult} setVariable={setVariable} submitCondition={submitCondition} result={result}/>
            <hr class="bg-success border-2 border-top border-success" /> 

            <h3>Your conditions</h3>
            {conditions.map((condition)=>(<Condition deleteCondition={deleteCondition} condition={condition} weWantEditCondition={weWantEditCondition}/>))}
            
        </div>
    )
}

const Condition = ({condition, weWantEditCondition, deleteCondition}) => {
    return(
        <div>
        <div class="card" >
                
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Low Value: {condition.range0}</li>
                    <li class="list-group-item">High Value: {condition.range1}</li>
                    <li class="list-group-item">Variable: {condition.variable}</li>
                    <li class="list-group-item">Result: {condition.result}</li>



                    <li class="list-group-item"><span class="badge bg-danger" onClick={()=>{deleteCondition(condition)}}>Delete</span><span class="badge bg-warning" onClick={()=>{weWantEditCondition(condition)}}>Edit</span></li>

                </ul>
            </div>
            <br />

            </div>
            
    )

}
const ConditionEdit = ({editCondition, result,setResult, setRange0, setRange1, setVariable, submitCondition,variablesAvail}) => {
    return(
        <div>
            <div class="input-group">
                    <span class="input-group-text">If</span>
                <select id="questionType" class="form-control" onChange={(e)=>{setVariable(e.target.value)}}>
                    <option value='nothing' disabled selected>Variable</option>
                    {variablesAvail.map((variable)=>(<VariableOption name={editCondition.variable} thisVar={variable}/>))}
                

                </select>
            </div>
           
           
                 
                
            <br />
                <div class="input-group mb-3">
                <span class="input-group-text">Is greater than</span>

            <input type="text" class="form-control" value={editCondition.range0} placeholder=".05" aria-label="Username" onChange={(e)=>{setRange0(e.target.value)}}/>
            <span class="input-group-text">and less than</span>
            <input type="text" class="form-control" value={editCondition.range1} placeholder="20" aria-label="Server" onChange={(e)=>{setRange1(e.target.value)}}/>
            </div>
            <p>Use {'\{$VARIABLEHERE\}'} to insert a value from the results (Make sure there are no spaces that are not in the variable)</p>
            <div class="input-group">
                    <span class="input-group-text">Then Add to email</span>
                    <textarea value={result} class="form-control" aria-label="With textarea" onChange={(e)=>{setResult(e.target.value)}}></textarea>
                    <select class="form-select" id="customVar" aria-label="Default select example" onChange={(e)=>{setResult(editCondition.result+ "{$" + e.target.value+`}`); document.getElementById('customVar').selectedIndex = 0}}>
                      <option selected disabled>Add Variable</option>
                      {variablesAvail.map((variable)=>(<option>{variable}</option>))}

                    </select>
                </div>
                <div dangerouslySetInnerHTML={{__html: editCondition.result}}></div>

                <br />
                <a className="btn btn-success" onClick={submitCondition}>Submit Condition</a>
            </div>
    )

}

const VariableOption = ({name, thisVar}) => {
    if(name == thisVar){
        return(
<option selected>{thisVar}</option>
            )
    }
    return(
        <option >{thisVar}</option>
    )
    
}



export default PostSubEmail
