import React, { useState, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar'
import Modal from 'react-modal'

const Radar = ({data, keys, indexBy, openSubmissionModal, maximize}) => {
    const [modalIsOpen, setIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      
    function openModal() {
        setIsOpen(true);
      }
    
      function afterOpenModal() {

      }
    
      function closeModal() {
        setIsOpen(false);
      }
      if(!maximize){
        return(
          <div style={{height: 400,width: 400}}>

          <div style={{height: "100%",width:"100%"}}>
          <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}/>
          </div>
          </div>
        )
      }
    return (
        <div style={{height: 400,width: 400}}>
                        <button onClick={openModal} ><i className="fas fa-expand" style={{position: 'absolute', top: 0, right : 0, fontSize: 40}}></i></button>
                        <div style={{height: "100%",width:"100%"}}>
                        <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}/>
                        </div>
                        
                        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="btn btn-danger fas fa-times" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, right : 0}}></button>
        <div style={{height : '75vh', width : '75vw'}}>
        <Graph data={data} keys= {keys} indexBy={indexBy} openSubmissionModal={openSubmissionModal}/>

        </div>
      </Modal>
        </div>


)
    
}


const Graph = ({data,keys, indexBy, openSubmissionModal})=>{
    return(     <ResponsiveRadar
        data={data}
        keys={keys}
        indexBy={indexBy}
        valueFormat=">-.2f"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={{ scheme: 'nivo' }}
        blendMode="multiply"
        motionConfig="wobbly"
        legends={[
            {
                anchor: 'top-left',
                direction: 'column',
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />)
}

export default Radar
