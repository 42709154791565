import React, { useState, useEffect } from 'react';

const EditGeneral = ({token, serverAddress}) => {
    const [siteTitle, setSiteTitle] = useState('')
    const [favicon, setFavicon] = useState('')
    const [desc, setDesc] = useState('')
    const [title, setTitle] = useState('')
    const [before, setBefore] = useState('')
    const [after, setAfter] = useState('')
    const [redirect, setRedirect] = useState('')
    const [update, setUpdate] = useState(0)



    async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status === 200){
                if(dataFromServer.data.design){
                    
                    setTitle(dataFromServer.data.design.title)
                    setBefore(dataFromServer.data.design.before)
                    setAfter(dataFromServer.data.design.after)
                    setRedirect(dataFromServer.data.design.redirect)
                    setSiteTitle(dataFromServer.data.design.metadata.title)
                    setFavicon(dataFromServer.data.design.metadata.favicon)
                    setDesc(dataFromServer.data.design.metadata.desc)
                }
                
        
            }
        }
       runGet()
        
      }, [ update ])
      async function updateSurvey(body) {
        return fetch(serverAddress+'/admin/addSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
      function getQueryVariable(variable)
      {
              var query = window.location.search.substring(1);
              //console.log(query)//"app=article&act=news_content&aid=160990"
              var vars = query.split("&");
              //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
              for (var i=0;i<vars.length;i++) {
                          var pair = vars[i].split("=");
                          //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
              if(pair[0] == variable){return pair[1];}
               }
               return(false);
      }

      async function submitDesign() {
        var designBody = {
            title,
            before,
            after,
            redirect,
            metadata : {
                title : siteTitle,
                favicon,
                desc

            }
           
        }
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'design' : designBody
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
        
        }



       }




    return (
        <div>
            <h1>General Survey Settings</h1>
            <br />
            <h3>Metadata Settings</h3>
            <div class="input-group">
                    <span class="input-group-text">Website Title</span>
                    <input value={siteTitle} class="form-control" aria-label="With textarea" onChange={(e)=>{setSiteTitle(e.target.value)}} />
                </div>

                <br />
                <div class="input-group">
                    <span class="input-group-text">Website ICON Link</span>
                    <input value={favicon} class="form-control" aria-label="With textarea" onChange={(e)=>{setFavicon(e.target.value)}} />
                </div>

                <br />
                <div class="input-group">
                    <span class="input-group-text">Website Description</span>
                    <input value={desc} class="form-control" aria-label="With textarea" onChange={(e)=>{setDesc(e.target.value)}} />
                </div>

                <br />

            <div class="input-group">
                    <span class="input-group-text">Survey Title</span>
                    <input value={title} class="form-control" aria-label="With textarea" onChange={(e)=>{setTitle(e.target.value)}} />
                </div>

                <br />
            <div class="input-group">
                    <span class="input-group-text">Before Survey Begins (Can be code)</span>
                    <textarea value={before} class="form-control" aria-label="With textarea" onChange={(e)=>{setBefore(e.target.value)}}></textarea>
                </div>
                <div dangerouslySetInnerHTML={{__html: before}}></div>


                <br />

                <div class="input-group">
                    <span class="input-group-text">After Survey/before email (Can be code)</span>
                    <textarea value={after} class="form-control" aria-label="With textarea" onChange={(e)=>{setAfter(e.target.value)}}></textarea>
                </div>
                <div dangerouslySetInnerHTML={{__html: after}}></div>


                <br />
                <div class="input-group">
                    <span class="input-group-text">Redirect After Submission</span>
                    <input value={redirect} class="form-control" aria-label="With textarea" onChange={(e)=>{setRedirect(e.target.value)}} />
                </div>
                <br />


                <a className="btn btn-primary" onClick={()=>{submitDesign()}}>Save</a>
        </div>
    )
}

export default EditGeneral
