import React, { useState, useEffect } from 'react';

const SurveySettings = (props) => {
    const [seeResults, setSeeResults] = useState('')


    async function updateSurvey(body) {
        return fetch(props.serverAddress+'/admin/addSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : props.token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }

    
    async function handleSubmit(e){
        e.preventDefault()
        console.log(document.getElementById('seeResults').checked)
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'userSeeResults' : document.getElementById('seeResults').checked
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                window.location.reload()
        
        }

    }
    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                    
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }
    return (
        <div>
            <h1>Survey Settings</h1>
            <br />
            <form onSubmit={handleSubmit}>
                <div class="form-check">
                <input class="form-check-input" type="checkbox" value="THIS IS THE VALUE" id="seeResults" onChange={(e)=>{setSeeResults(e.target.value)}}/>
                <label class="form-check-label" for="flexCheckDefault">
                    Should users see their results?
                </label>
	    <br/>
	    <p>If this is enabled, add {"{$RESULTSLINK}"} anywhere in the email to place the link in a custom location</p>
                </div>
                <button className="btn btn-success" type="submit">Submit</button>
            </form>
           




        </div>
    )
}

export default SurveySettings
