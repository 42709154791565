import React, { useState, useEffect } from 'react';

const Submission = ({serverAddress}) => {
    const [submission, setSubmission] = useState({userInputs : [], results : {}})
    const [survey, setSurvey] = useState({design: {metadata:{}}, results: {}});
    const [topVariables, setTopVariable] = useState({})

     //Get extra data from server
     async function getSubmission() {
        return fetch(serverAddress+'/survey/get/submission', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'submissionid' :getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
       async function getSurvey(id) {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'surveyid' :id
          }
        })
          .then(data => data.json())
       }
    //Getting inital
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSubmission();
            if(dataFromServer.status == 200){
                console.log("Got the survey")
                console.log(dataFromServer.data)
                setSubmission(dataFromServer.data);
                const surveyData = await getSurvey(dataFromServer.data.surveyId);
                if(surveyData.status == 200){
                    setSurvey(surveyData.data)
                }



                
               
                
        
            }
        }
       runGet()
        
      }, [ ]);


    //Get the top variables
    useEffect(() => {
        console.log("SURVEY", survey)
        if(survey.questions){
            var topResults = {}
            survey.questions.map((question)=>{
                question.options.map((option)=>{
                    option.variables.map((variable)=>{
                        if(topResults[variable.variable]){
                            topResults[variable.variable] += variable.weight * option.value
                        }else{
                            topResults[variable.variable] = variable.weight * option.value
                        }
                    })
    
                })
            })
            console.log("Tio resykts", topResults)
            setTopVariable(topResults)
        }
       
       
    },[ survey ])

   

      function getQueryVariable(variable)
      {
              var query = window.location.search.substring(1);
              //console.log(query)//"app=article&act=news_content&aid=160990"
              var vars = query.split("&");
              //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
              for (var i=0;i<vars.length;i++) {
                          var pair = vars[i].split("=");
                          //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
              if(pair[0] == variable){return pair[1];}
               }
               return(false);
      }
      if(!getQueryVariable('id')){
          return(
              <h1>Doesn't seem like thats a submission</h1>
          )
      }
    return (
        <div className='container'>
            <div className='row'>

              <div className='col'>

                  <h1>Questions and Your Answers</h1>
              {submission.userInputs.map((thisQuestion)=>(<Question question={thisQuestion} />))}
              </div>

            
            </div>


        </div>
    )
}

const Question = ({question}) => {
    var color
    if(question.variablesAffected && question.variablesAffected.length > 0){
        color = 'success'
    }else{
        color = 'danger'
    }
    return(
        <div>
            <div class="card">
                <div class={"card-header"}>
                    {question.question}
                </div>
                <div class="card-body">
                    <blockquote class="blockquote mb-0">
                    {question.answered ? <p>Your answer: {question.answer}</p> : <p>Not answered</p>}
                    </blockquote>
                </div>
                </div>

                <br />

        </div>

       

    )
}
/*
const Variable = ({thisVariable, ourVariable, topVariable}) => {
   

    return(
        <div>
                <li class="list-group-item">{thisVariable}<br />{ourVariable+"/"+topVariable}</li>
                <br />
                <div className='col'>
              <ul class="list-group list-group-flush">
              {Object.keys(topVariables).map((thisVariable)=>(
                    <Variable thisVariable={thisVariable} ourVariable={(submission.results[thisVariable])? submission.results[thisVariable]: 0}  topVariable={topVariables[thisVariable]} />
                ))}
            </ul>
               

              </div>

        </div>

       

    )
}
*/

export default Submission
