import React, { useState, useEffect } from 'react';

const AddSurvey = ({serverAddress, token, updateSurveys, setUpdate}) => {
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    async function addProperty(body) {
        return fetch(serverAddress+'/admin/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token 
          },
          body: JSON.stringify(body),
        
        })
          .then(data => data.json())
       }
    
      const handleSubmit = async e => {
          if(name != ''  && desc !=''){
            e.preventDefault();
            const fromServer = await addProperty({
              name, desc
            });
            if(fromServer.status == 201){
                document.getElementById('nameInput').value = ''//document.getElementById('nameInput').placeholder
                document.getElementById('descInput').value = ''//document.getElementById('descInput').placeholder
                setDesc('')
                setName('')
                setUpdate(updateSurveys +1)
            }
          }else{
              alert("All values must be filled in")
          }
      
      
      }
    return (
        <div>
       
     
              <div class="col" data-bs-toggle="modal" data-bs-target="#addSurvey">
        <div class="card" >
  <div class="card-body container" style={{textAlign : 'center'}}>
  <i class="fa fa-plus" aria-hidden="true" style={{fontSize : "80px", color : "lightblue"}}></i>
 </div>
  </div>

</div>


<div class="modal fade" id="addSurvey" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Survey</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="row">
        <div class="col">
            <label for="nameInput" class="form-label">Name</label>

            <input id="nameInput" type="text" class="form-control" placeholder="openAnalytics" aria-label="Name" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
       
        </div>
        <div class="mb-3">
  <label for="descInput" class="form-label">Description</label>
  <textarea class="form-control" id="descInput" rows="3" placeholder="Platform for measuring how many pickles rick can turn into" onChange={(e)=>{setDesc(e.target.value)}}></textarea>
</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" onClick={handleSubmit} data-bs-dismiss="modal">Add Survey</button>
      </div>
    </div>
  </div>
</div> 
        </div>
    )
}

export default AddSurvey