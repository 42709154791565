import React from 'react'

const SideBar = () => {
    return (
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{width: "280px", height : "calc(100vh - 77.5px)"}}>
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <svg class="bi me-2" width="40" height="32"><use  href="#bootstrap"></use></svg>
          <span class="fs-4">Survey</span>
        </a>
        <hr />
        <ul class="nav nav-pills flex-column mb-auto" role="tablist">
          <li class="nav-item">
            <a href="#" class="nav-link link-dark active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true">
              <i className="fas fa-edit"></i> Questions/Variables
              
            </a>
          </li>
          <li>
            <a href="#" class="nav-link link-dark" id="dist-tab" data-bs-toggle="tab" data-bs-target="#dist" role="tab" aria-controls="dist" aria-selected="false">
            <i className="fas fa-underline"></i> Design/Extra Text
    
            </a>
          </li>
          <li>
            <a href="#" class="nav-link link-dark" id="set-tab" data-bs-toggle="tab" data-bs-target="#set" role="tab" aria-controls="set" aria-selected="false">
            <i className="fas fa-cog"></i> Survey Settings
    
            </a>
          </li>
        
        
        </ul>
        <hr />
       
      </div>
    )
}

export default SideBar
