import React from 'react'

const ShowAdmin = ({admin, deleteComponent}) => {
    return (
      <li class="list-group-item">
       
  {admin}
  <span class="position-absolute top-50 start-100 translate-middle badge rounded-pill bg-danger" onClick={()=>{deleteComponent({thisAdmin : admin})}}>
    X
  </span>
  </li>

    )
}

export default ShowAdmin
