import React, { useState, useEffect } from 'react';
import AddSurvey from './AddSurvey';

const Admin = ({token, serverAddress, setToken}) => {
    const [surveys, setSurveys] = useState([])
    const [updateSurveys, setUpdate] = useState(0)
  
    async function getData() {
        return fetch(serverAddress+'/admin/surveys', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
          }
        })
          .then(data => data.json())
       }
       async function renameSurvey(surveyId) {
         var name = prompt("What would you like to rename to?")
        return fetch(serverAddress+'/admin/rename', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
          },
          body : JSON.stringify({survey : surveyId, name: name})
        })
          .then(data => {setUpdate(updateSurveys+1)})
       }
       async function duplicateSurvey(surveyId) {
        return fetch(serverAddress+'/admin/duplicate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token
          },
          body : JSON.stringify({survey : surveyId})
        })
          .then(data => {setUpdate(updateSurveys+1)})
       }
       useEffect(() => {
        async function runGet() {
            const dataFromServer = await getData();
            console.log(dataFromServer)
            if(dataFromServer.status == 200){
                setSurveys(dataFromServer.data);
               
                
                console.log("This is the right information")
        
            }else{
                setToken({token : undefined})
              
                console.error("This is the wrong information",token)
            }
        }
       runGet()
        
      }, [updateSurveys]);
    return (
        <div className="container">
            <h1 className="display-text">Surveys</h1>
          
            <div class="row row-cols-1 row-cols-md-3 g-4">

            {surveys.map((survey, index) => (<Survey survey={survey} index = {index} token = {token} serverAddress = {serverAddress} updateSurveys = {updateSurveys} duplicateSurvey ={duplicateSurvey} setUpdate = {setUpdate} renameSurvey={renameSurvey}/>))}
            <AddSurvey serverAddress={serverAddress} token={token} setUpdate={setUpdate} updateSurveys={updateSurveys} />
        </div>
      

        </div>
    )
}


const Survey = ({survey, index, serverAddress, updateSurveys, setUpdate, token, duplicateSurvey, renameSurvey}) => {
    return(
        <div class="col">
        <div class="card" >
  <div class="card-body">
    <h5 class="card-title" onClick={()=>{window.open('/surveyPage?id='+survey.id)}}>{survey.name} </h5>
    <div class="dropstart" style={{position: 'absolute', top: 0, right : 0, fontSize : 25, color : 'black'}}>
  <a className="btn fas fa-bars" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
  </a>

  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <li><a onClick={()=>{window.open('/editSurvey?id='+survey.id)}} class="dropdown-item">Edit</a></li>
    <li><a onClick={()=>duplicateSurvey(survey.id)} class="dropdown-item">Duplicate</a></li>

    <li><a onClick={()=>renameSurvey(survey.id)} class="dropdown-item">Rename</a></li>
  </ul>
</div>

    <h6 class="card-subtitle mb-2 text-muted">Total submissions : {survey.submissions} </h6>
    <p class="card-text">{survey.desc}</p>
<br />

    <a href={'https://survey.evaluable.co/' +survey.id} class="btn btn-success card-link">Visit</a>
    <a href={'/submissions?id=' +survey.id} class="btn btn-warning card-link">Submissions</a>


  </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js" integrity="sha384-pprn3073KE6tl6bjs2QrFaJGz5/SUsLqktiwsUTF55Jfv3qYSDhgCecCxMW52nD2" crossorigin="anonymous"></script>
</div>
    )
}
//  <DeleteProperty token = {token} serverAddress = {serverAddress} survey = {survey} updateSurveys = {updateSurveys} setUpdate = {setUpdate}/>

const DeleteProperty = ({token, serverAddress, property, updateSurveys, setUpdate}) => {


  const [toDelete, setToDelete] = useState([])

    async function deleteProperty(body) {
        return fetch(serverAddress+'/removeProperty', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'id': property.id
          },
          body: JSON.stringify(body),
        
        })
          .then(data => data.json())
       }
    
      const handleSubmit = async e => {
         
            e.preventDefault();
            console.log("sending")
            const fromServer = await deleteProperty();
            if(fromServer.status){
                setUpdate(updateSurveys+1)
                alert("Property has been deleted")
               
            }
        
      
      
      }
    return (
        <div >
       



<div class="modal fade" id={property.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Property</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       
      
       <p>Are you sure you want to delete: {property.title}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" onClick={handleSubmit} data-bs-dismiss="modal">Delete Property</button>
      </div>
    </div>
  </div>
</div> 
        </div>
    )
}




export default Admin