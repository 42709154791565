import React, { useState, useEffect } from 'react';

const SubmissionCSV = ({submissions, JSONToCSVConvertor, collectors}) => {
    const [dist, setDist] = useState('')

    return (
        <div>
            <h2>Sort By Collector</h2>
            <select class="form-select" aria-label="Default select example" onChange={(e)=>{setDist(e.target.value)}}>
  <option selected>Select Collector</option>
  <option value = ''>All</option>

  {collectors.map((collector)=>(  <option value={collector.id}>{collector.name}</option>
))}
</select>
   <br />
            <p>Submission Count: {submissions.length}</p>
            <button type="button" class="btn btn-success" onClick={()=>{JSONToCSVConvertor(submissions, "openSurvey", true, dist)}}>Download As CSV</button>
            <table class="table">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Date</th>
                </tr>
            </thead>
            <tbody>

                {submissions.map((submission, index)=>(<Submission submission = {submission} index={index} dist={dist}/>))}
              
            </tbody>
            </table>     
        </div>
    )
}
const Submission = ({submission, index, dist}) => {
    if(submission.collector === dist || dist == ""){
        if(submission.results){
            return(
                <tr>
                        <th scope="row">{index +1}</th>
                        <td>{submission.email}</td>
                        <td>{submission.date}</td>
                        {Object.keys(submission.results).sort().map((result)=>(<td>{result + " " +submission.results[result]}</td>))}
                        
                        </tr>
            )
        }
        return(
            <tr>
                    <th scope="row">{index +1}</th>
                    <td>{submission.email}</td>
                    <td>{submission.date}</td>
                    
                    </tr>
        )
        
    }
    return(null)
   
}
export default SubmissionCSV
