import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'
import Radar from '../../Graphs/Radar';
import TileSm from '../../Tiles/TileSm';
export const SubmissionModal = (props) => {
 
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      
    
    
      function afterOpenModal() {

      }
    
      function closeModal() {
        props.setSubmissionModalOpen(false)
      }
    return (
        <div>
            
             
  <Modal
        isOpen={props.submissionModalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

      <button className="btn fas fa-arrow-circle-left" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, left : 0, fontSize : 25}}></button>

        <button className="btn btn-danger fas fa-times" type="button" onClick={closeModal} style={{position: 'absolute', top: 0, right : 0}}></button>
        <div style={{height : '75vh', width : '75vw'}}>
            <ThisSubmission 
            data={props.submissionModalData} 
            submissions={props.submissions}
            openQuestionModal={props.openQuestionModal}
            />
        </div>
      </Modal>
            
        </div>
    )
}

const ThisSubmission = ({data, submissions,openQuestionModal}) => {
  const [thisSubmission, setThisSubmission] = useState({results:{}, userInputs:[]})
  const [radarData, setRadarData] = useState([])
  const [totalQuestionStats, setTotalQuestionStats] = useState({processSub:{}})
  const [questionStats, setQuestionStats] = useState({})

  function format(time) {   
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + "h" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + "m" + (secs < 10 ? "0" : "");
    ret += "" + secs +'s';
    return ret;
}

  var small = [{title: "Avg Q Time", number : format(totalQuestionStats.avgTimePerQ)},{title: "Total Time", number : format(totalQuestionStats.totalTime)},{title: "% Finished", number :((totalQuestionStats.processSub.completed/totalQuestionStats.processSub.total)*100).toFixed(2)+'%'}]

  useEffect(() => {  
    var tempQStats = {}
    var processSub = {
        completed : 0,
        total : 0
    }
    var submission = thisSubmission
        if(submission.userInputs){
            var doneAll = true
        submission.userInputs.map((question)=>{
            processSub.total += 1

            if(question.answered){
                processSub.completed += 1

            var split = question.time.split(':')
            var inSeconds = parseInt(split[0])* 60 + parseInt(split[1])
            
            if(tempQStats[question.id]){
                tempQStats[question.id].totalTime += inSeconds
                tempQStats[question.id].timeCount += 1
                //Check if our answer has been answered yey
                if(tempQStats[question.id].answer[question.answer]){
                    tempQStats[question.id].answer[question.answer] += 1
                }else{
                    //If the answer does not exist then we add it
                    tempQStats[question.id].answer[question.answer] = 1
                }



            }else{
                var tempAnswer = {}
                tempAnswer[question.answer] = 1
                tempQStats[question.id] = {
                    totalTime : inSeconds,
                    timeCount : 1,
                    answer : tempAnswer

                    

                }
            }
        }
        })


     
        }

    setQuestionStats(tempQStats)

    var totalQStats = {
        totalTime : 0,
        avgTime : 0,
        timeCount : 0,
        processSub

    }

    //Now we will get overall question statistics
    Object.keys(tempQStats).map((thisQ)=>{
        totalQStats.totalTime += tempQStats[thisQ].totalTime
        totalQStats.timeCount += tempQStats[thisQ].timeCount
    })
    totalQStats.avgTimePerQ = parseInt(totalQStats.totalTime/totalQStats.timeCount)
    if(thisSubmission.time){
      var split = thisSubmission.time.split(':')
      var inSeconds = parseInt(split[0])* 60 + parseInt(split[1])
      totalQStats.totalTime = inSeconds
      setTotalQuestionStats(totalQStats)
    }
   
    

}, [ thisSubmission]);


  useEffect(() => {
      for(var i=0;i<submissions.length;i++){
        if(submissions[i]._id === data.id){
          setThisSubmission(submissions[i])
          break;
        }
      }
     
    
  },[ data ])
  useEffect(() => {
    var tempData = []
    //Create Radar
    console.log('Our results', thisSubmission.results)
    Object.keys(thisSubmission.results).map((thisVariable)=>{tempData.push({
      variable : thisVariable,
      "Avg Value": thisSubmission.results[thisVariable]
  })})
  setRadarData(tempData)
  
  },[ thisSubmission ])
  useEffect(() => {
    var tempData = []
    //Now we calculate time
    //Create Radar
    console.log('Our results', thisSubmission.results)
    Object.keys(thisSubmission.results).map((thisVariable)=>{tempData.push({
      variable : thisVariable,
      "Avg Value": thisSubmission.results[thisVariable]
  })})
  setRadarData(tempData)
  
  },[ thisSubmission ])



  



  return(<div>

    <h3 className="text-center">{thisSubmission.email} - {thisSubmission.date}</h3>
 
  <br />


  <div class="row row-cols-1 row-cols-md-3 g-4">
              {small.map((tile)=>(<TileSm tile={tile}/>))}

    </div>

    <div style={{height:400, width:400}}>
    <Radar data={radarData} keys={['Avg Value']} indexBy={'variable'}/>

    

    </div>
    <UserInputResult userInputs={thisSubmission.userInputs} openQuestionModal={openQuestionModal}/>
  </div>

)


}

const UserInputResult = ({userInputs, openQuestionModal}) => {
  return(
    <div>
      <h2>Answers</h2>
      <ol class="list-group list-group-numbered">
             
             {userInputs.map((thisQuestion)=>(
             <QuestionItem 
              question={thisQuestion}
              openQuestionModal={openQuestionModal}
              />
             ))}
            
             </ol>
    </div>

  )
}

const QuestionItem = ({question,openQuestionModal}) => {
  if(question.answer){
    return(

      <li class="list-group-item d-flex justify-content-between align-items-start" onClick={()=>{openQuestionModal(question)}}> 
      <div class="ms-2 me-auto">
      <div class="fw-bold">{question.question}</div>
      {question.answer}
      </div>
      <span class="badge bg-primary rounded-pill">{question.time}</span>
  </li>
  )
  }
  return(

      <li class="list-group-item d-flex justify-content-between align-items-start" onClick={()=>{openQuestionModal(question)}}>
      <div class="ms-2 me-auto">
      <div class="fw-bold">{question.question}</div>
      <span class="badge bg-danger rounded-pill">Not Answered</span>
      </div>
      <span class="badge bg-primary rounded-pill">{question.time}</span>
  </li>
  )
}
