import React, { useState, useEffect } from 'react';
import Pie from '../../../Graphs/Pie';

const DomainPie = ({submissions}) => {
    const [data,setData] = useState([])

    useEffect(() => {
        //We will organize the variable radar data here
        var tempData = []
        var emailCount = {}
        submissions.map((submission)=>{
            var thisEmail = submission.email.split('@')[1]
            if(emailCount[thisEmail]){
                emailCount[thisEmail] += 1
            }else{
                emailCount[thisEmail] = 1
            }
        })
        Object.keys(emailCount).map((thisEmail)=>{
            tempData.push({
                id:thisEmail,
                value : emailCount[thisEmail], 
                color : randomColor(),
                percent :((emailCount[thisEmail]/submissions.length)*100).toFixed(0)+'%'
            })
        })
        tempData.sort((b, a) => a.value - b.value)
       
        setData(tempData)
       

        
        
      }, [ submissions]);
      const randomColor = () => {
        let color = '#';
        for (let i = 0; i < 6; i++){
           const random = Math.random();
           const bit = (random * 16) | 0;
           color += (bit).toString(16);
        };
        return color;
     };

     //            {data.map((email)=>(<ListItem item={email} />))}
    
      
    return (
        <div style={{height : 400, width : 400}}>
            <Pie data={data} />
            </div>
        
       
    )
}




export default DomainPie
