import React from 'react'

const Home = ({logo}) => {
    return (
        <div class="px-4 py-5 my-5 text-center">
          <img src={logo} height="120px" width="auto"/>
    <h1 class="display-5 fw-bold">Evaluable</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Software that allows you to collect multiple data points from one question.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a type="button" class="btn btn-primary btn-lg px-4 gap-3" href='/admin'>Get Started</a>
      </div>
    </div>
  </div>
    )
}

export default Home