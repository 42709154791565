import React, { useState, useEffect } from 'react';

const DisplaySurvey = ({questions, deleteComponent, editQuestion}) => {
    const [score, setScore] = useState({});
    const [varScores, setVarScore] = useState({});
    const [updateScore, setUpdateScore] = useState(0);
   /* const [questions, setQuestions] = useState([
    {
        question : "Question2", 
        type : 'multipleChoice', 
        variables : [{variable : 'a', weight : 1}, {variable : 'b', weight : -5}, {variable : 'c', weight : 4}],
        id: 'weifub',
        options : [{option : "True", value : 10, variables : [{variable : 'a', weight : 2}]}, {option : "False", value : 1, variables : [{variable : 'b', weight : 5}]}]
    },
    {
        question : "Question3", 
        type : 'range', 
        variables : [{variable : 'a', weight : 1}, {variable : 'b', weight : -5}, {variable : 'c', weight : 4}],
        id: 'ofieoianhw'
    }])*/
    
    useEffect(() =>{
        console.log("Score updated", score)
        var allQuestions = Object.keys(score)
        var tempVarScores = {}
        for(var i =0;i<allQuestions.length;i++){
            console.log("Question" + i)
            var allvars = Object.keys(score[allQuestions[i]])
            for(var x =0;x<allvars.length;x++){
                console.log('upidating', allvars[x],tempVarScores[allvars[x]])
                if(tempVarScores[allvars[x]]){
                    tempVarScores[allvars[x]] +=  score[allQuestions[i]][allvars[x]]

                }else{
                    tempVarScores[allvars[x]] =  score[allQuestions[i]][allvars[x]]

                }

            }


        }
        setVarScore(tempVarScores)
        console.log("TEMP VAR SCORES", tempVarScores)


  },[ updateScore ])

  var variables = ['a','b']

    return (
        <div className='container'>
                          <strong>Resulting variables and their values</strong>

            {Object.keys(varScores).map((variable)=>(<p>{variable + varScores[variable]}</p>))}
            {questions.map((question)=>(<Question question={question} score={score} setScore={setScore} updateScore ={updateScore} setUpdateScore={setUpdateScore} deleteComponent={deleteComponent} editQuestion={editQuestion}/>))}

            
        </div>
    )
}



const Question = ({question, score, setScore,updateScore, setUpdateScore, deleteComponent, editQuestion}) => {
    var values = {}

    const handleUpdate = async (value, newVars) => {
        console.log("RUNNING", newVars)
        var placeholder = newVars
        
        if(!placeholder){
            placeholder = question.variables

        }
        for(var i =0;i<placeholder.length ;i++){
            values[placeholder[i].variable] = value * placeholder[i].weight
        }
        var tempScore = score
        tempScore[question.id] = values
        setScore(tempScore)
        setUpdateScore(updateScore + 1)
    }
    if(question.type == 'multipleChoice' || question.type == ''){
        return(
            <div>
            <MultipleChoice question={question} handleUpdate={handleUpdate}/>
            <span class="badge bg-danger" onClick={()=>{deleteComponent({question : question})}}>Remove Question</span>
            <span class="badge bg-warning" onClick={()=>{editQuestion(question)}}>Edit Question</span>

            <br />

            </div>
        )
    }
    else if(question.type == 'select'){
        return(
            <div>
            <Select question={question} handleUpdate={handleUpdate}/>
            <span class="badge bg-danger" onClick={()=>{deleteComponent({question : question})}}>Remove Question</span>
            <span class="badge bg-warning" onClick={()=>{editQuestion(question)}}>Edit Question</span>

            <br />

            </div>
        )
    }else if(question.type == 'shortAnswer'){
        return(
            <div>
                            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>

                <h3>{question.question}</h3>
            <span class="badge bg-danger" onClick={()=>{deleteComponent({question : question})}}>Remove Question</span>
            <span class="badge bg-warning" onClick={()=>{editQuestion(question)}}>Edit Question</span>
            </div>
        )
    }else{
        return(
            <div>
            <MultipleChoice question={question} handleUpdate={handleUpdate}/>
            <br />

            </div>
        )
    }
}
const Range = ({question, handleUpdate}) => {
    return(
        <div>
<label for="customRange2" class="form-label">            <strong>{question.question}</strong>
</label>
<input type="range" class="form-range" min="0" max="10" id="customRange2" onChange={(e)=>{handleUpdate(e.target.value)}} />
        </div>

    )

}
const MultipleChoice = ({question, handleUpdate}) => {
    return(
<fieldset class="form-group">
            <div class="row">
            <h3>{question.question}</h3>
            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>

              <ul>
              {question.variables.map((variable)=>(<li>{variable['variable'] +'   ' + variable['weight']}</li>))}

              </ul>
              <div class="col-sm-10">
                {question.options ? question.options.map((option)=>(<MultipleChoiceOption option={option} handleUpdate={handleUpdate} id = {question.id} />)) : null}
               
                
              </div>
            </div>
          </fieldset>
    )

}
const MultipleChoiceOption = ({option, handleUpdate, id}) => {
    return(
        <div class="form-check">
        
    <input class="form-check-input" type="radio" name={id} value={option.value} onChange={(e)=>{handleUpdate(e.target.value, option.variables)}}/>
    <label class="form-check-label" for="gridRadios1">
      {option.option}
    </label>
  </div>
    )
}

const Select = ({question, handleUpdate}) => {

    async function selectedOption(selected) {
        //Since unlike multiple choice questions, select ones only let use use onChange on the head. So there is so way to directly pass data from the option selected to the handleUpdate function
        question.options.map((option)=>{
            if(option.id === parseFloat(selected)){
                handleUpdate(option.value, option.variables,option.option)

            }
        })
    }

    return(
    <fieldset class="form-group">
            <div class="row">
            <div dangerouslySetInnerHTML={{__html : question.embed}}></div>
            <h2>{question.question}</h2>

            <select class="form-select" aria-label="Default select example" onChange={(e)=>{selectedOption(e.target.value)}}>
            {question.options.map((option)=>(<SelectOption option={option} key={option.id} />))}

            </select>
            
            </div>
          </fieldset>
    )

}
const SelectOption = ({option}) => {
    return(
      <option value={option.id}>{option.option}</option>
   
    )
}
  

export default DisplaySurvey
