import React, { useState, useEffect } from 'react';
import { stringify } from 'query-string';
import ShowVariable from './Variable'
import { useParams } from 'react-router-dom';
import DisplaySurvey from './DisplaySurvey/DisplaySurvey';
import ShowAdmin from './Admin';
const EditQuestion = ({token, serverAddress}) => {
    const [question, setQuestion] = useState({variables : [], question : '', options : []})
    const [options, setOptions] = useState([]);
    const [order,setOrder] = useState()
    const [questions, setQuestions] = useState([]);
    const [questionType, setQuestionType] = useState('')
    const [toEmbed, setToEmbed] = useState('');
    const [qRequired, setQrequired] = useState(false);
    const [variables, setVariables] = useState([])
    const [variablesAvail, setVariablesAvail] = useState([]);
    const [questionTypeOptions, setQuestionTypeOptions] = useState(['Multiple Choice','Short Answer','Select']);
    const [update, setUpdate] = useState(0);
    const [updatedOptions, setUpdatedOptions] = useState(0);
    const [survey, setSurvey] = useState({admins : []});



    async function getSurvey() {
        return fetch(serverAddress+'/survey/get', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
            'surveyid' : getQueryVariable('id')
          }
        })
          .then(data => data.json())
       }
       async function updateSurvey(body) {
        return fetch(serverAddress+'/admin/addSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }
       async function deleteSurveyComponent(body) {
        return fetch(serverAddress+'/admin/deleteSurveyComponents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : token,
          },
          body : JSON.stringify(body)
        })
          .then(data => data.json())
       }

       

       //Getting inital
       useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurvey();
            if(dataFromServer.status == 200){
                setSurvey(dataFromServer.data);
                if(dataFromServer.data.questions){
                    setQuestions(dataFromServer.data.questions)
                }
                if(dataFromServer.data.variables){
                    setVariablesAvail(dataFromServer.data.variables)
                }
               
                
        
            }
        }
       runGet()
        
      }, [ update ]);







      //Updating the variables
      async function submitVariable(variable) {
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'variable' : variable
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
        
        }



       }
       async function submitAdmin(thisAdmin) {
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'thisAdmin' : thisAdmin
        }
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                setUpdate(update + 1)
        
        }



       }
       
       //Delete a component 
       async function deleteComponent(body) {
           var result = window.confirm("Are you sure?")
        if(result){
            body.surveyId =  getQueryVariable('id')
     
            const dataFromServer = await deleteSurveyComponent(body);
                if(dataFromServer.status == 201){
                    setUpdate(update + 1)
            
            }
    
        }
         


       }







        

   
    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            //console.log(query)//"app=article&act=news_content&aid=160990"
            var vars = query.split("&");
            //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                    
                        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if(pair[0] == variable){return pair[1];}
             }
             return(false);
    }


    //This is when we are adding a new question
    async function submitQuestion() {
        setQuestions([...questions, question])
        var questionObj = question
        questionObj.embed = toEmbed
        questionObj.required = qRequired
        questionObj.variables = variables
        var updateBody = {
            'surveyId' : getQueryVariable('id'),
            'question' : question,
        
        }
        console.log("This is pretty coole", updateBody)
        const dataFromServer = await updateSurvey(updateBody);
            if(dataFromServer.status == 201){
                window.location.reload()
        
        }

    
        setQuestion({variables : [], question : '', options : [], id: Math.random()})
        setOptions([])
        setToEmbed('')
        setQrequired(false)
        document.getElementById('questionRequired').checked = false
        document.getElementById('questionType').value = 'nothing'
        document.getElementById('questionName').value = ''



       }



       async function editQuestion(question) {
           if(window.confirm("Are you sure you want to edit this question?")){
            console.log("Run Handle Update")
            setQuestion(question)
            setOptions(question.options)
            setToEmbed(question.embed)
            setQuestionType(question.type)
            setVariables(question.variables)

            setQrequired(question.required)
            document.getElementById('questionRequired').checked = question.required
            document.getElementById("questionName").value = question.question
           }
        
        

       }

       //THIS IS PART OF THE CALCULATOINS
    async function handleUpdate(key, value) {
        console.log("Run Handle Update")
        var tempQuestion = question
        tempQuestion[key] = value
        console.log(tempQuestion)
        setQuestion(tempQuestion)
       }
     

       useEffect(() =>{
        console.log(question)
        var tempQuestion = question
        tempQuestion.options = options
        setQuestion(tempQuestion)
    
        
      }, [ options ])

      //This is updating the question componenets
      async function updateOption(index, option) {

        console.log("Update", option.id)
        var newOptions = options
        newOptions[index] = option
        setOptions(newOptions)
        setUpdatedOptions(updatedOptions + 1)

        
    }
    async function addOption() {
        console.log("Add")

        setOptions([...options,{id: Math.random()}])
        
        
    }
    async function removeOption(option) {
        console.log("Remove", option.id)
        const data = options.filter(i => i.id !== option.id)
        setOptions(data)

       
    }
    useEffect(() => {
        handleUpdate('type', questionType)
        
      }, [ questionType ]);

       
    return (

        <div className='container'>
            <h1>{survey.name}</h1>
            <p>{survey.desc}</p>
            <div className='row'>

            
           
            <div className="col">
                <h2>Add Question</h2>
                <hr />
                <p>Add HTML before question</p>
                
                <div class="input-group">
                    <span class="input-group-text">Embed Code</span>
                    <textarea value={toEmbed} class="form-control" aria-label="With textarea" onChange={(e)=>{setToEmbed(e.target.value)}}></textarea>
                </div>
                <div class="input-group">
                <span class="input-group-text">Order</span>
                    <input type='number' value={question.order?question.order:null} class="form-control" aria-label="With textarea" onChange={(e)=>{setQuestion({...question, order : e.target.value})}}/>
                </div>
                <br />
                <div dangerouslySetInnerHTML={{__html: toEmbed}}></div>
                <hr class="bg-success border-2 border-top border-success" /> 

                <div class="input-group mb-3">
        <input id="questionName" type="text" class="form-control" placeholder="Fill this out" aria-label="Username" aria-describedby="basic-addon1" placeholder="What is the actual question?" onChange={(e)=>{handleUpdate('question', e.target.value)}}/>
    </div>
            <select id="questionType" class="form-control" onChange={(e)=>{setQuestionType(e.target.value)}}>
                <option value='nothing' disabled selected >Select type of question</option>
                {questionTypeOptions.map((type)=>(<QuestionTypeOptions thisVar={type} name={questionType} />))}



            </select>
            <br />
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value={qRequired} id="questionRequired" onChange={(e)=>{ setQrequired(e.target.checked)}}/>
            <label class="form-check-label" for="questionRequired">
                Question Required?
            </label>
            </div>
            <br />

            <QuestionVariables variables={variables} setVariables={setVariables} variablesAvail={variablesAvail}/>

            <QuestionType questionType={questionType} options={options} updateOption={updateOption} addOption={addOption} removeOption={removeOption} variablesAvail={variablesAvail} questionVariables={variables}/>

            <hr class="bg-danger border-2 border-top border-danger" /> 
            <button className="btn btn-info" onClick={submitQuestion}>Submit question</button>
                <br />
                <br />
            <h2>Survey Demo</h2>
                <hr />
            <DisplaySurvey questions={questions} deleteComponent={deleteComponent} editQuestion={editQuestion}/>


        </div>
        

        <div className="col-sm-3 fixed">
                <h2>Add Variable</h2>
                <hr />
                <div class="form-floating mb-3">
                <input class="form-control" id="addVariable" placeholder="Variable Name" />
                <label for="floatingInput">Variable Name</label>
                </div>
                <button className = "btn btn-success" onClick={()=>{submitVariable(document.getElementById('addVariable').value); document.getElementById('addVariable').value = '';}} > Add Variable</button>
                <br />
                <br />
                
                <ul class="list-group list-group-flush">
 

                {variablesAvail.map((variable)=>(<ShowVariable variable={variable} deleteComponent={deleteComponent}/>))}

                </ul>            
             
                <h2>Add Admin</h2>
                <hr />
                <div class="form-floating mb-3">
                <input class="form-control" id="addAdmin" placeholder="Admin User ID" />
                <label for="floatingInput">User ID</label>
                </div>
                <button className = "btn btn-success" onClick={()=>{submitAdmin(document.getElementById('addAdmin').value); document.getElementById('addAdmin').value = '';}} > Add Admin</button>
                <br />
                <br />
                
                <ul class="list-group list-group-flush">
 

                {survey.admins.map((thisAdmin)=>(<ShowAdmin admin={thisAdmin} deleteComponent={deleteComponent}/>))}

                </ul>            
        
        
        </div>

        </div>
</div>



    )
}

const QuestionVariables = ({variables, setVariables, variablesAvail}) =>{
  
      //This is updating the question componenets
    async function updateVariable(index, variable) {

        console.log("Update", variable.id)
        var newVariable = variables
        newVariable[index] = variable
        setVariables(newVariable)

        
    }
    async function addVariable() {
        console.log("Add")

        setVariables([...variables,{id: Math.random()}])
        
        
    }
    async function removeVariable(variable) {
        console.log("Remove", variable.id)
        const data = variables.filter(i => i.id !== variable.id)
        setVariables(data)

       
    }

    return(
        <div>
            <Variables variables={variables} updateVariable={updateVariable} removeVariable={removeVariable} addVariable={addVariable} variablesAvail={variablesAvail} text={"to QUESTION"}/>

        </div>

    )

}

const QuestionTypeOptions = ({name, thisVar}) => {

   
    var value = ''
    if(thisVar.includes(' ')){
        value = thisVar.split(' ')[0].toLowerCase() + thisVar.split(' ')[1]

    }else{
        value = thisVar.toLowerCase()
    }
    if(name === value){
        return(
<option value={value} selected>{thisVar}</option>
            )
    }
    return(
        <option value={value}>{thisVar}</option>
    )
    
}

const QuestionType = ({options, addOption, updateOption, removeOption, variablesAvail, questionType, questionVariables}) => {
    if(questionType === 'multipleChoice' || 'select'){
        return(
            <Options options={options} updateOption={updateOption} addOption={addOption} removeOption={removeOption} variablesAvail={variablesAvail} questionVariables={questionVariables}/>

        )

    }else if(questionType === 'shortAnswer'){
        return(null)
    }else{
        return(null)
    }

}


const ShortAnswerQuestions = ({options, addOption, updateOption, removeOption, variablesAvail}) => {

    
    return (
        <div>
        {options.map((option)=>(<Option option={option} key={option.id} updateOption={updateOption} variablesAvail={variablesAvail} removeOption={removeOption}/>))}
        <hr class="bg-danger border-2 border-top border-info" /> 

        <button className='btn btn-primary' onClick={()=>{addOption()}}>Add Option</button>

        </div>
    )
}

const Options = ({options, addOption, updateOption, removeOption, variablesAvail, questionVariables}) => {

    
    return (
        <div>
        {options.map((option)=>(<Option option={option} key={option.id} updateOption={updateOption} variablesAvail={variablesAvail} removeOption={removeOption} questionVariables={questionVariables}/>))}
        <hr class="bg-danger border-2 border-top border-info" /> 

        <button className='btn btn-primary' onClick={()=>{addOption()}}>Add Option</button>

        </div>
    )
}
const Option = (props) => {
    const [title, setTitle] = useState(props.option.option);
    const [value, setValue] = useState(props.option.value);
    const [firstStart, setFirstStart] = useState(false);
    const [updateVariables, setUpdateVariables] = useState(0);
    const [variables, setVariables] = useState([]);
    
    useEffect(() =>{
        if(props.option.variables){
          setVariables(props.option.variables)
        }
        
        
      }, [])
      //This is updating the question componenets
    async function updateVariable(index, variable) {

        console.log("Update", variable.id)
        var newVariable = variables
        newVariable[index] = variable
        setVariables(newVariable)
        setUpdateVariables(updateVariables + 1)

        
    }
    async function addVariable() {
        console.log("Add")

        setVariables([...variables,{id: Math.random()}])
        
        
    }
    async function removeVariable(variable) {
        console.log("Remove", variable.id)
        const data = variables.filter(i => i.id !== variable.id)
        setVariables(data)

       
    }






    useEffect(() =>{
        if(firstStart){
            var thisOption = props.option
            thisOption.option = title
            thisOption.value = value
            thisOption.variables = variables
            props.updateOption(props.index, thisOption)
        }else{
            setFirstStart(true)

        }
        
        
      }, [ value, title, variables ])

    return (
        <div>
                        <hr class="bg-danger border-2 border-top border-info" /> 
        <div className="row"> 
            <div className="col">
                <input type="text" class="form-control" value={title} placeholder='option' onChange={(e)=>{setTitle(e.target.value)}}/>
            </div>
         <br />
            <div className="col">         
                <input type="number" class="form-control" value={ value} placeholder='value' onChange={(e)=>{setValue(parseFloat(e.target.value))}}/>
            </div>
            <div className="col"> 
                <a className="btn btn-danger" onClick={()=>{props.removeOption(props.option)}}>Delete</a>
            </div>
        </div>
<br />

{(props.questionVariables.length === 0) && <Variables variables={variables} updateVariable={updateVariable} removeVariable={removeVariable} addVariable={addVariable} variablesAvail={props.variablesAvail}/>}

        
        </div>
    )
}

const Variables = ({variables, addVariable, removeVariable, updateVariable, variablesAvail, text}) => {
    
    
    return (
        <div>
            <div>
        {variables.map((variable)=>(<Variable variable={variable} key={variable.id} updateVariable ={updateVariable} removeVariable={removeVariable} variablesAvail={variablesAvail} />))}

        <button className='btn btn-primary' onClick={()=>{addVariable()}}>Add variable {text}</button>

        <br />

            </div>
        </div>
    )
}
const Variable = (props) => {
    const [firstStart, setFirstStart] = useState(false);
    const [weight, setWeight] = useState(props.variable.weight);
    const [name, setName] = useState(props.variable.variable);

    
    useEffect(() =>{
        if(firstStart){
            var thisVariable = props.variable
            thisVariable.variable = name
            thisVariable.weight = weight
            props.updateVariable(props.index, thisVariable)
        }else{
            setFirstStart(true)

        }
        
        
      }, [ weight, name ])
    return (
       
            <div class="input-group mb-3">
                <input value={weight} type='number' class="btn btn-outline" placeholder="weight" onChange={(e)=>{setWeight(e.target.value)}}/>
                <select class="form-select" id={Math.random()} aria-label="Example select with button addon" onChange={(e)=>{setName(e.target.value)}}>
                    <option selected>Choose...</option>
                    {props.variablesAvail.map((thisVar)=> (<VariableOption name={name} thisVar={thisVar}/>))}
                </select>
                <a className="btn btn-danger" onClick={()=>{props.removeVariable(props.variable)}}>Delete</a>

            </div>

    )
}


const VariableOption = ({name, thisVar}) => {
    if(name == thisVar){
        return(
<option selected>{thisVar}</option>
            )
    }
    return(
        <option >{thisVar}</option>
    )
    
}



export default EditQuestion