import React, { useState, useEffect } from 'react';
import { SubmissionModal } from './Modals/Submission/SubmissionModal';
import getSubmissions from './GetSubmissions';
import Submissions from './Submissions';
import QuestionModal from './Modals/Submission/QuestionModal';

const ModalManager = (props) => {
    const [submissionModalOpen, setSubmissionModalOpen] = useState(false)
    const [submissionModalData, setSubmissionModalData] = useState({})
    function openSubmissionModal(data) {
        setSubmissionModalOpen(true)
        setSubmissionModalData(data)
      
      }

    const [questionModalOpen, setQuestionModalOpen] = useState(false)
    const [questionModalData, setQuestionModalData] = useState({})
    function openQuestionModal(data) {
        setQuestionModalOpen(true)
        setQuestionModalData(data)
      
      }
    function closeQuestionModal() {
        setQuestionModalOpen(false)
      
      }

    return (
        <div>

            <Submissions 
            submissions={props.submissions}
            collectors = {props.collectors}
            serverAddress = {props.serverAddress}
            token={props.token}
            update={props.update}
            setUpdate={props.setUpdate}
            openSubmissionModal={openSubmissionModal} 
            openQuestionModal ={openQuestionModal}

            />

            <SubmissionModal 
            submissions = {props.submissions} 
            submissionModalData={submissionModalData} 
            setSubmissionModalData={setSubmissionModalData} 
            submissionModalOpen={submissionModalOpen} 
            setSubmissionModalOpen={setSubmissionModalOpen} 
            openQuestionModal={openQuestionModal}
            />

            <QuestionModal
            submissions = {props.submissions} 
            questionModalData={questionModalData} 
            setQuestionModalData={setQuestionModalData} 
            questionModalOpen={questionModalOpen} 
            setQuestionModalOpen={setQuestionModalData} 
            closeQuestionModal = {closeQuestionModal}
            />
            
        </div>
    )
}

export default ModalManager
