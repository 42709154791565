import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg'
import Header from './Components/Header/Header'
import Questions from './Components/Questions/Questions'
import Admin from './Containers/Admin/Admin'
import AddSurvey from './Containers/Admin/EditSurvey/EditSurvey'
import Testing from './Containers/Testing/Testing';
import Survey from './Containers/Survey/Survey'
import Home from './Containers/Home/Home'
import React, { useState, useEffect } from 'react';
import Register from './Containers/Register/Register'
import useToken from './Components/useToken';
import { BrowserRouter, Route, Switch, useLocation} from 'react-router-dom';
import { Helmet } from "react-helmet"


//Images
import Logo from './logo.svg'

import Login from './Containers/Login/Login'
import EditSurvey from './Containers/Admin/EditSurvey/EditSurvey';
import Submissions from './Containers/Admin/Submission/Submissions';
import GetSubmissions from './Containers/Admin/Submission/GetSubmissions';

import Submission from './Containers/Submission/Submission'
function App() {
  const serverAddress = "https://backend.evaluable.co"
  const backupAddress = "https://198.71.56.91:3005"
  //const serverAddress = "http://localhost:3005"

  
  const { token, setToken } = useToken();

  async function logout() {
    setToken({token : undefined})
   }

 


  if(!token) {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" >
              <Header logo = { logo } token = {undefined}></Header>
              <Home logo={logo}/>
            </Route>
            <Route exact path="/survey">
                <Survey serverAddress = {serverAddress} backupAddress={backupAddress} token={token}/>
              </Route>

            <Route path = "/login">
              <Header logo = { logo } token = {undefined}></Header>
              <Login logo={logo} setToken={setToken} serverAddress = {serverAddress}/>
            </Route>

            <Route  path = "/register">
              <Header logo = { logo } token = {undefined}></Header>
              <Register setToken={setToken} serverAddress = {serverAddress} logo = {logo}/>
            </Route>

            <Route  path = "/mySubmission">
              <Header logo = { logo } token = {undefined}></Header>
              <Submission serverAddress={serverAddress}/>
            </Route>

            <Route >
              <Header logo = { logo } token = {undefined}></Header>
              <Login logo={logo} setToken={setToken} serverAddress = {serverAddress}/>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>)
  }
  return (
    <div>
      <BrowserRouter >
      <Header logo = {logo} logout = {logout} token={token}/>
        <Switch>
          <Route exact path="/" >
            <Home logo={logo}/>
          </Route>
          <Route exact path="/submissions">
            <GetSubmissions token={token} serverAddress={serverAddress}/>
          </Route> 
          <Route  path = "/mySubmission">
              <Submission serverAddress={serverAddress}/>
            </Route>
          <Route exact path="/editSurvey">
            <EditSurvey serverAddress = {serverAddress} token={token}/>
          </Route>
          <Route exact path="/survey">
            <Survey serverAddress = {serverAddress} backupAddress={backupAddress} token={token}/>
          </Route>
          <Route path="/surveyPage">
            <br />
            <Survey serverAddress = {serverAddress} token={token}/>
          </Route>
          <Route path="/admin">
            <br />
            <Admin results = {'no'} questions = {'no'} setQuestions={'no'} serverAddress = {serverAddress} setToken = {setToken} token = {token}/>
          </Route>
          <Route >
            <h1>Not found</h1>
          </Route>
        </Switch>
      </BrowserRouter>
    
    </div>
  );
}

export default App;
